import { useRef } from 'react'

import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'

import RichTextField from 'components/Form/RichTextField/RichTextField'
import IdeaFlameMotivator, {
	useRichTextToIdeas,
} from 'components/IdeaFlameMotivator/IdeaFlameMotivator'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section3.styles'

export const TEST_ID = 'leap-frog-section3'

export type Props = {
	authorIdeation: string
	buttonDisabled: () => boolean
	cautionText?: string
	handleSubmitIdeas: () => void
	handleUserResponse: (user: string) => void
	isAiLoading: boolean
	isLoading: boolean
	isSubmitButtonLoading: boolean
	viewSubmittedIdeas: () => void
}

const Section3 = ({
	isLoading,
	isAiLoading,
	authorIdeation,
	handleUserResponse,
	buttonDisabled,
	handleSubmitIdeas,
	isSubmitButtonLoading,
	cautionText,
	viewSubmittedIdeas,
}: Props) => {
	const richTextFieldRef = useRef(null)
	const richTextToIdeas = useRichTextToIdeas()

	return (
		<>
			<Typography
				sx={{ ...commonStyles.header, ...styles.header }}
				data-testid={`${TEST_ID}__heading`}
			>
				3. IDEATION
			</Typography>
			<Box
				display={'flex'}
				sx={{
					'.ProseMirror': {
						border: '1.5px solid var(--color-gray-4)',
					},
					'.ProseMirror-focused': {
						border: '2.5px solid var(--color-gray-4)',
					},
				}}
			>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Typography
						sx={{ ...commonStyles.header, ...styles.header }}
						data-testid={`${TEST_ID}__ideation-text`}
					>
						Document any ideas inspired by the facilitation
						technique to address your innovation topic.
					</Typography>
					<Grid
						item
						xs={12}
						sm={10}
						sx={{
							minHeight: { xs: '15rem', sm: '20rem' },
						}}
						data-testid={`${TEST_ID}__ideation`}
					>
						<RichTextField
							ref={richTextFieldRef}
							onChange={handleUserResponse}
							value={authorIdeation}
							placeholderText="Brainstorm your ideas here!"
							iconColor="#45657B"
							sx={{
								background: '#E4F4D8',
								border: '1.5px solid rgba(0,0,0,0.23)',
							}}
						/>
					</Grid>
					<Box>
						<IdeaFlameMotivator
							ideaCount={richTextToIdeas(authorIdeation)}
							variant="LEAP_FROG"
						/>
					</Box>
					<Grid container sx={styles.buttonsContainer}>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__submit-idea-button`}
							onClick={handleSubmitIdeas}
							disabled={
								buttonDisabled() || isLoading || isAiLoading
							}
						>
							{isLoading || isSubmitButtonLoading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>SUBMIT IDEAS</Typography>
							)}
						</Button>
						<Button
							disabled={isLoading}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__view-submit-ideas-button`}
							onClick={viewSubmittedIdeas}
						>
							{isLoading || isSubmitButtonLoading ? (
								<CircularProgress
									color="secondary"
									data-testid={`${TEST_ID}__view-all-submitted-ideas-circular-progress`}
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>
									VIEW ALL SUBMITTED IDEAS
								</Typography>
							)}
						</Button>
					</Grid>
					<Box>
						<Typography
							variant="body2"
							color="black"
							sx={{ opacity: 0.6 }}
							data-testid={`${TEST_ID}__caution-text`}
						>
							{cautionText}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Section3
