export const styles = {
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	chip: {
		paddingX: '0.5rem',
		color: 'black',
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background:
			'linear-gradient(180deg, rgba(0, 37, 230, 0.9) -12.94%, rgba(39, 35, 249, 0.5) 100%)',
	},
	header: {
		marginY: '2rem',
	},
	checkbox: {
		paddingLeft: '9px',
	},
	checkboxText: { color: 'black', fontSize: '16px' },
}
