export const styles = {
	BackArrowButton: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '0.25rem',
		whiteSpace: 'nowrap',
	},
	BreadcrumbContainer: {
		justifyContent: 'start',
		alignItems: 'center',
		color: '#45657b',
		display: 'flex',
		flexDirection: 'row',
		gap: '1.5rem',
		marginTop: '1rem',
		marginBottom: '1rem',
	},
	GameNameContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '0.5rem',
	},
	GameNameDescription: {
		fontStyle: 'italic',
	},
	GameNameText: {
		display: 'flex',
		gap: '.25rem',
	},
	GameNameTitle: {
		fontWeight: 'fontWeightBold',
	},
	GameIcon: {
		height: '2.8rem',
		width: '2.8rem',
	},
}
