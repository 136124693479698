import React, { useEffect, useState } from 'react'

import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
	Snackbar,
	Typography,
} from '@mui/material'

import { Idea } from 'models/ideaModels'

import { useAuth } from 'contexts/AuthProvider'

import { contactSlalom } from 'services/email.service'

import {
	alertStyles,
	dialogContainerStyles,
	dialogPaperStyles,
	dialogStyles,
	snackbarStyles,
	styles,
} from './PrototypeSubmittedIdeaModal.styles'

// TODO: this file needs to be re-written in the ModalProvider framework
// right now, it's operating on its own so it needs to be included in every page
// you want this rendered

export interface Props {
	handleClose: () => void
	idea: Idea
	open: boolean
}

export function stripHtmlTagsAndRetainNewlines(html: string): string {
	// Replace <br> tags with newline characters
	html = html.replace(/<br\s*\/?>/gi, '\n')

	// Replace block-level tags with newline characters
	html = html.replace(
		/<\/?(p|div|h[1-6]|li|ol|ul|blockquote|pre|table|tr|td|th)\b[^>]*>/gi,
		'\n'
	)

	// Create a temporary DOM element to use the browser's HTML parsing capability
	const div = document.createElement('div')
	div.innerHTML = html

	// Extract the text content from the temporary element
	const textContent = div.textContent || ''
	const innerText = div.innerText || ''
	let text = textContent || innerText

	// Decode HTML entities
	const textarea = document.createElement('textarea')
	textarea.innerHTML = text
	text = textarea.value

	// Normalize consecutive newlines (remove consecutive newlines except for 2)
	text = text.replace(/\n{3,}/g, '\n\n')

	// Trim leading and trailing whitespace/newlines
	text = text.trim()

	return text
}

const PrototypeSubmittedIdeaModal: React.FC<Props> = ({
	idea,
	open,
	handleClose,
}) => {
	const [isSelected, setIsSelected] = useState(false)
	const [showSuccessToast, setShowSuccessToast] = useState(false)
	const [showErrorToast, setShowErrorToast] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const { user } = useAuth()

	// Reset state when modal is opened or closed
	useEffect(() => {
		if (!open) {
			// Reset state when modal is closed
			setIsSelected(false)
			setIsSubmitting(false)
		}
	}, [open])

	const handlePaperClick = (event: React.MouseEvent) => {
		event.stopPropagation()
	}

	const handleLearnMoreClick = () => {
		window.open('https://go.slalom.com/arc', '_blank')
	}

	const handleRequestPocClick = async () => {
		try {
			setIsSubmitting(true)
			const ideas = stripHtmlTagsAndRetainNewlines(idea.authorIdeation)
			const userEmail = user.email || ''

			await contactSlalom(ideas, userEmail)
			setShowSuccessToast(true)
			// The useEffect will reset state when handleClose sets open to false
			handleClose()
		} catch (error) {
			// Silent error handling with UI feedback
			setShowErrorToast(true)
			setIsSubmitting(false) // Only reset here as modal stays open on error
		}
	}

	const handleSelect = () => {
		setIsSelected(!isSelected)
	}

	const handleCloseToast = () => {
		setShowSuccessToast(false)
		setShowErrorToast(false)
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				sx={dialogContainerStyles}
				PaperProps={{
					style: dialogPaperStyles,
					onClick: handlePaperClick,
				}}
			>
				<Box sx={dialogStyles}>
					<DialogTitle
						sx={styles.title}
						data-testid="prototype-modal-title"
					>
						Request a Prototype to Test Your Ideas
					</DialogTitle>
					<DialogContent sx={styles.contentBox}>
						<Box sx={styles.paragraph}>
							<Typography
								sx={styles.typography}
								data-testid="prototype-modal-genai-text"
							>
								GenAI can hypothesize on if an idea could work.
							</Typography>
							<Typography
								sx={styles.typographyWithMargin}
								data-testid="prototype-modal-arc-text"
							>
								Slalom&apos;s ARC Labs can drive confidence on
								if your ideas actually work.
							</Typography>
							<Typography
								sx={styles.typographyWithBottomMargin}
								data-testid="prototype-modal-experiments-text"
							>
								ARC builds real-world experiments to test your
								ideas around the following metrics, based on
								your unique stakeholders and constraints:
							</Typography>

							<List sx={styles.list}>
								<ListItem sx={styles.paragraph.flexItem}>
									<Box
										component="span"
										sx={styles.bulletPoint}
									>
										•
									</Box>
									<ListItemText
										primary={
											<>
												<Typography
													component="span"
													style={styles.boldText}
													data-testid="prototype-modal-feasibility"
												>
													Feasibility{' '}
												</Typography>
												<Typography
													component="span"
													sx={styles.typography}
													data-testid="prototype-modal-feasibility-desc"
												>
													- is it possible?
												</Typography>
											</>
										}
										sx={styles.listItemText}
									/>
								</ListItem>
								<ListItem sx={styles.paragraph.flexItem}>
									<Box
										component="span"
										sx={styles.bulletPoint}
									>
										•
									</Box>
									<ListItemText
										primary={
											<>
												<Typography
													component="span"
													style={styles.boldText}
													data-testid="prototype-modal-viability"
												>
													Viability{' '}
												</Typography>
												<Typography
													component="span"
													sx={styles.typography}
													data-testid="prototype-modal-viability-desc"
												>
													- is it profitable?
												</Typography>
											</>
										}
										sx={styles.listItemText}
									/>
								</ListItem>
								<ListItem sx={styles.paragraph.flexItem}>
									<Box
										component="span"
										sx={styles.bulletPoint}
									>
										•
									</Box>
									<ListItemText
										primary={
											<>
												<Typography
													component="span"
													style={styles.boldText}
													data-testid="prototype-modal-desirability"
												>
													Desirability{' '}
												</Typography>
												<Typography
													component="span"
													sx={styles.typography}
													data-testid="prototype-modal-desirability-desc"
												>
													- is it preferable?
												</Typography>
											</>
										}
										sx={styles.listItemText}
									/>
								</ListItem>
							</List>
						</Box>
					</DialogContent>
					<DialogContent sx={styles.titleBox}>
						<Typography
							variant="body1"
							paragraph
							sx={styles.partnerTitle}
							data-testid="prototype-modal-partner-title"
						>
							Partner with Slalom&apos;s ARC Labs to expedite
							validating your ideas.
						</Typography>
						<Typography
							variant="body1"
							paragraph
							sx={styles.experimentTitle}
							data-testid="prototype-modal-experiment-title"
						>
							1 month of live experimentation = confidence on if
							your idea is worth scaling.
						</Typography>
						<Checkbox
							component="span"
							onClick={handleSelect}
							data-testid="prototype-modal-checkbox"
						/>
						<Typography
							component="span"
							variant="body1"
							paragraph
							sx={styles.experimentTitle}
							data-testid="prototype-modal-share-text"
						>
							Share with Slalom the ideas I&#39;m interested in
							them testing
						</Typography>
					</DialogContent>
					<DialogActions sx={styles.actionBox}>
						<Button
							variant="contained"
							style={
								isSubmitting
									? styles.submittingButton
									: isSelected
										? styles.primaryButton
										: undefined
							}
							onClick={handleRequestPocClick}
							disabled={!isSelected || isSubmitting}
							data-testid="prototype-modal-connect-button"
						>
							{isSubmitting
								? 'CONNECTING...'
								: 'CONNECT WITH SLALOM'}
						</Button>
						<Button
							variant="outlined"
							style={styles.secondaryButton}
							onClick={handleLearnMoreClick}
							data-testid="prototype-modal-learn-more-button"
						>
							LEARN MORE ABOUT ARC LABS
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
			<Snackbar
				open={showSuccessToast}
				autoHideDuration={6000}
				onClose={handleCloseToast}
				anchorOrigin={snackbarStyles.anchorOrigin}
			>
				<Alert
					onClose={handleCloseToast}
					severity="success"
					sx={alertStyles}
					data-testid="prototype-modal-success-toast"
				>
					A Slalom team member will be in touch soon!
				</Alert>
			</Snackbar>
			<Snackbar
				open={showErrorToast}
				autoHideDuration={6000}
				onClose={handleCloseToast}
				anchorOrigin={snackbarStyles.anchorOrigin}
			>
				<Alert
					onClose={handleCloseToast}
					severity="error"
					sx={alertStyles}
					data-testid="prototype-modal-error-toast"
				>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>
		</>
	)
}

export default PrototypeSubmittedIdeaModal
