import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'

export function OppositeDayModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')
	const [termsAgreed, setTermsAgreed] = useState(false)

	const submitIdeasDisabled = () => {
		if (
			generateScenarioDisabled() ||
			!authorIdeation ||
			(gameId && !termsAgreed)
		)
			return true
		return false
	}

	const generateScenarioDisabled = () =>
		[innovationTopic, innovationCompany, companyProblem].some(
			(field) => !field.length
		)

	const gameTypeId = GAME_TYPE.OPPOSITE_DAY.value

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}

	const updateCompanyAdvice = async () => {}

	const reset = () => {
		setAuthorIdeation('')
	}

	return {
		authorIdeation,
		setAuthorIdeation,
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		innovationTopic,
		setInnovationTopic,
		setGameId,
		generateScenarioDisabled,
		gameToJSON,
		updateCompanyAdvice,
		termsAgreed,
		setTermsAgreed,
		submitIdeasDisabled,
		reset,
	}
}
