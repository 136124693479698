import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Container, Grid } from '@mui/material'
import logger from 'utils/logger'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { OppositeDayModel } from 'models/OppositeDayModel'
import { Game } from 'models/gameModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import { postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'

import { alertTitleTextMap } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { SUBMITTED_IDEAS } from 'assets/routes'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'
import { BusinessTopicType } from 'enums/TopicTypeEnum'

import Hero from '../../components/Hero/Hero'
import { styles } from './OppositeDay.styles'
import Section1 from './components/Section1/Section1'
import Section2 from './components/Section2/Section2'
import Section3 from './components/Section3/Section3'

export const TEST_ID = 'opposite-day'

const OppositeDay = ({ game }: { game?: Game }) => {
	useEffect(() => {
		if (game) {
			setInnovationCompany(game.innovationCompany)
			setInnovationTopic(game.innovationTopic)
			setCompanyProblem(game.companyProblem)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const {
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		authorIdeation,
		setAuthorIdeation,
		innovationTopic,
		setInnovationTopic,
		setGameId,
		generateScenarioDisabled,
		termsAgreed,
		setTermsAgreed,
		updateCompanyAdvice,
		submitIdeasDisabled,
		gameToJSON,
		reset,
	} = OppositeDayModel()

	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)
	const [submitIdeas, setSubmitIdeas] = useState(false)

	const { setIdeaStreakCount } = useIdeaStreakContext()
	const { showAlert } = useToastContext()
	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	const resetAllField = () => {
		setInnovationCompany('')
		setInnovationTopic('')
		setCompanyProblem('')
	}

	const refreshPrompt = async () => {}

	const createPrompt = async () => {
		try {
			setIsLoading(true)

			setStep(GAME_STEP.Step2)
			window.scrollTo(0, 0)
		} finally {
			setIsLoading(false)
		}
	}

	const viewSubmittedIdeas = () => {
		window.scrollTo(0, 0)
		navigate(SUBMITTED_IDEAS)
	}

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(gameToJSON())

			if (showAlert) {
				showAlert({
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
			setIdeaStreakCount((prevState) => prevState + 1)
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert({
					title: alertTitleTextMap.submitIdeaError.title,
					bodyText: alertTitleTextMap.submitIdeaError.text,
					severity: ToastSeverity.ERROR,
				})
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)

			// need to reset the model
			reset()
			window.scrollTo({
				top: 0,
			})

			// Load new prompt
			createPrompt()
		}
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.OPPOSITE_DAY}
				innovationTopic={innovationTopic as BusinessTopicType}
				innovationCompany={innovationCompany}
				companyProblem={companyProblem}
				isLoading={isLoading}
				setCompanyProblem={setCompanyProblem}
				setInnovationCompany={setInnovationCompany}
				setInnovationTopic={setInnovationTopic}
				createPrompt={createPrompt}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth={step === GAME_STEP.Step1 ? 'md' : 'lg'}
				sx={styles.container}
			>
				<Box sx={styles.breadcrumbBox}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === GAME_STEP.Step1,
								onClick:
									step !== GAME_STEP.Step1
										? () => setStep(GAME_STEP.Step1)
										: undefined,
							},
							{
								label: '2. FACILITATION TECHNIQUE & 3. IDEATION',
								active: step !== GAME_STEP.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<Section1
						isLoading={isLoading}
						innovationCompany={innovationCompany}
						setInnovationCompany={setInnovationCompany}
						innovationTopic={innovationTopic as BusinessTopicType}
						setInnovationTopic={setInnovationTopic}
						companyProblem={companyProblem}
						setCompanyProblem={setCompanyProblem}
						generateScenarioDisabled={generateScenarioDisabled}
						resetAllField={resetAllField}
						createPrompt={createPrompt}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
					/>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<Grid container>
							<Grid item md={6}>
								<Section2
									loading={isLoading}
									createPrompt={refreshPrompt}
									reloadIdeas={updateCompanyAdvice}
								/>
							</Grid>
							<Grid item md={6}>
								<Section3
									submitIdeasDisabled={submitIdeasDisabled}
									loading={isSubmitButtonLoading}
									submitIdeas={handleSubmitIdeas}
									authorIdeation={authorIdeation}
									setAuthorIdeation={setAuthorIdeation}
									viewSubmittedIdeas={viewSubmittedIdeas}
									cautionText={
										game?.gameTitle || game?.endTime
											? brainstormText.global
													.alternateWarningText
											: brainstormText.global.warningText
									}
									termsAgreed={termsAgreed}
									setTermsAgreed={setTermsAgreed}
									gameId={game?.gameId}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Container>
		</Box>
	)
}

export default OppositeDay
