import React, { useState } from 'react'

import { Button, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { styles } from './Section2.styles'

export const TEST_ID = 'opposite-day-section2'

export type Props = {
	createPrompt: () => void
	loading: boolean
	reloadIdeas: () => Promise<void>
}

const Section2 = ({ ...props }: Props) => {
	const [editPromptMode, setEditPromptMode] = useState(false)
	const [isGenAiLoading, setIsGenAiLoading] = useState(false)

	const onClickRefresh = () => {}

	const onClickEditPrompt = () => {
		setEditPromptMode(true)
	}

	const onClickCancelEditPrompt = () => {
		setEditPromptMode(false)
	}

	const onClickSaveEditPrompt = async () => {}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box style={styles.mainBox}>
				<Box sx={styles.sideBar} />
				<Box sx={styles.typographyBox}>
					<Typography
						sx={styles.typography}
						data-testid={`${TEST_ID}-user-directions-verbiage`}
					>
						Consider these intentionally terrible ideas and how the
						opposite of - or perhaphs an iteration of - these
						concepts could lead to novelty.
					</Typography>
					<Box sx={styles.refreshButtonBox}>
						<ButtonWithIconComponent
							disabled={props.loading}
							iconSvg={refreshIcon}
							text="REFRESH"
							data-testid={`${TEST_ID}__refresh-button`}
							onClickAction={onClickRefresh}
						/>
					</Box>
					<Paper elevation={0} sx={styles.paper}>
						<Typography sx={styles.typography}>
							This is where prompt shows. // This is where prompt
							shows. This is where prompt shows. This is where
							prompt shows. This is where prompt shows. This is
							where prompt shows. This is where prompt shows. This
							is where prompt shows. // This is where prompt
							shows. This is where prompt shows. This is where
							prompt shows. This is where prompt shows. This is
							where prompt shows. This is where prompt shows. This
							is where prompt shows. // This is where prompt
							shows. This is where prompt shows. This is where
							prompt shows. This is where prompt shows. This is
							where prompt shows. This is where prompt shows. //
							This is where prompt shows. This is where prompt
							shows. This is where prompt shows. This is where
							prompt shows. This is where prompt shows. // This is
							where prompt shows. This is where prompt shows. This
							is where prompt shows. This is where prompt shows.
							This is where prompt shows.
						</Typography>
					</Paper>
					{editPromptMode ? (
						<Box sx={styles.editPromptsBox}>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={async () => {
									setIsGenAiLoading(true)
									await onClickSaveEditPrompt()
									setIsGenAiLoading(false)
								}}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEditPrompt}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Box>
					) : (
						<Box sx={styles.editButtonBox}>
							<ButtonWithIconComponent
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT"
								data-testid={`${TEST_ID}-edit-prompt`}
								onClickAction={onClickEditPrompt}
							/>
						</Box>
					)}
					<Typography
						sx={styles.typography}
						data-testid={`${TEST_ID}-genai-idea-dropdown`}
					>
						{brainstormText.global.ideasDropdown}
					</Typography>
					<GeneratedExampleDropdown
						gameTypeEnum={GAME_TYPE.OPPOSITE_DAY}
						headerText={''}
						isLoading={isGenAiLoading}
					/>
				</Box>
			</Box>
		</>
	)
}
export default Section2
