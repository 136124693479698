export const styles = {
	card: {
		borderRadius: '0.6rem',
		border: 0,
		maxHeight: '72px',
		width: 'fit-content',
	},
	cardContentReset: {
		'&:last-child': {
			paddingBottom: 0,
		},
		height: '100%',
		margin: 0,
		overflowY: 'visible',
		padding: 0,
		position: 'center',
	},
	circularProgressIcon: {
		color: 'secondary.main',
		size: '2.8rem',
	},
	container: {
		minHeight: '9.8rem',
		position: 'center',
		padding: '16px 32px 16px 32px',
	},
	contentText: {
		color: '#000000DE',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		display: 'inline-block',
		marginTop: '5px',
	},
}
