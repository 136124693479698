import React, { useRef } from 'react'

import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { RichTextField } from 'components/Form'
import IdeaFlameMotivator, {
	useRichTextToIdeas,
} from 'components/IdeaFlameMotivator/IdeaFlameMotivator'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section3.styles'

export const TEST_ID = 'round-robin-section3'

export type Props = {
	authorIdeation: string
	cautionText?: string
	loading: boolean
	setAuthorIdeation: (val: string) => void
	submitIdeas: () => void
	submitIdeasDisabled: () => boolean
	viewSubmittedIdeas: () => void
}

const Section3 = (props: Props) => {
	const richTextFieldRef = useRef(null)
	const richTextToIdeas = useRichTextToIdeas()

	return (
		<>
			<Typography
				variant={'overline'}
				sx={{ ...commonStyles.header, ...styles.header }}
				data-testid={`${TEST_ID}__heading`}
			>
				3. IDEATION
			</Typography>
			<Box
				display={'flex'}
				sx={{
					'.ProseMirror': {
						border: '1.5px solid var(--color-gray-4)',
					},
					'.ProseMirror-focused': {
						border: '2.5px solid var(--color-gray-4)',
					},
				}}
			>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
				>
					<Typography
						sx={{ ...commonStyles.header, ...styles.header }}
						data-testid={`${TEST_ID}__ideation-text`}
					>
						Document any ideas inspired by the facilitation
						technique to address your innovation topic.
					</Typography>
					<Grid
						item
						xs={12}
						sm={10}
						sx={{
							minHeight: { xs: '15rem', sm: '20rem' },
						}}
						data-testid={`${TEST_ID}__ideation`}
					>
						<RichTextField
							ref={richTextFieldRef}
							onChange={props.setAuthorIdeation}
							value={props.authorIdeation}
							placeholderText="Brainstorm your ideas here!"
							sx={{
								background: '#FFE8EE',
								borderLeft: '1.5px solid rgba(0,0,0,0.23)',
								borderRight: '1.5px solid rgba(0,0,0,0.23)',
								borderTop: '1.5px solid rgba(0,0,0,0.23)',
							}}
							iconColor="#45657B"
						/>
					</Grid>
					<Box>
						<IdeaFlameMotivator
							ideaCount={richTextToIdeas(props.authorIdeation)}
							variant="ROUND_ROBIN"
						/>
					</Box>
					<Box
						display="flex"
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
						gap="2rem"
					>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__submit-idea-button`}
							onClick={props.submitIdeas}
							disabled={
								props.submitIdeasDisabled() || props.loading
							}
						>
							{props.loading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>SUBMIT IDEAS</Typography>
							)}
						</Button>
						<Button
							disabled={props.loading}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__view-submit-ideas-button`}
							onClick={props.viewSubmittedIdeas}
						>
							{props.loading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>
									VIEW ALL SUBMITTED IDEAS
								</Typography>
							)}
						</Button>
					</Box>
					<Typography
						color="black"
						sx={{ opacity: 0.6 }}
						data-testid={`${TEST_ID}__caution-text`}
					>
						{props.cautionText}
					</Typography>
				</Box>
			</Box>
		</>
	)
}

export default Section3
