import { useEffect, useRef, useState } from 'react'

import { Box, Chip, Divider, Grid, Typography } from '@mui/material'

import { CallToAction } from 'components/CallToAction/CallToAction'
import GameCard from 'components/GameCard/GameCard'

import { GAME_TOPIC_FILTERS, GAME_TYPE, GameType } from 'enums/GameTypeEnum'

import { styles } from './HomePage.styles'

const HomePage = () => {
	const gameTypeArray = (Object.values(GAME_TYPE) as GameType[]).filter(
		(game) => !!game.useCase
	)
	const elRef = useRef<null | HTMLDivElement>(null)

	// Maintaining Musical Chairs in coming soon list until ready for production
	// Toggle comment to view musical chairs locally
	const comingSoonList = ['Musical Chairs']
	// const comingSoonList = ['']

	const [selectedFilter, setSelectedFilter] = useState<string | null>(null)

	useEffect(() => {
		elRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		})
		selectedFilter !== null &&
			setTimeout(() => setSelectedFilter(null), 4000)
	}, [selectedFilter])

	return (
		<>
			<Box data-testid="home__container" sx={styles.container}>
				<Grid container data-testid="home__content" sx={styles.content}>
					<Grid
						data-testid="home__content_title"
						item
						px={6}
						sx={styles.homePage}
						xs={12}
					>
						<Typography sx={styles.header}>
							Create more lightbulb moments
						</Typography>
						<Typography sx={styles.subheader}>
							Facilitation frameworks + GenAI prompts sparking
							human creativity
						</Typography>
					</Grid>
				</Grid>
				<Divider
					data-testid="home__game_card_container_top_divider"
					sx={styles.gameCardTopDivider}
				></Divider>
				<Grid container sx={styles.outerFiltersContainer}>
					<Typography sx={styles.filtersHeader}>
						Level up your creativity with lateral thinking
						techniques
					</Typography>
					<Box
						data-testid="home__filter-pills"
						sx={styles.innerFiltersContainer}
					>
						<Typography sx={styles.filerLabel}>
							Filter by topic:
						</Typography>
						{Object.entries(GAME_TOPIC_FILTERS).map(
							([key, value]) => (
								<Chip
									label={value}
									variant={'outlined'}
									key={key}
									onClick={() => {
										setSelectedFilter(value)
									}}
									sx={styles.chip}
								/>
							)
						)}
					</Box>
				</Grid>
				<Box
					data-testid="home__game_card_container"
					sx={styles.gameCardContainer}
				>
					<Grid container sx={styles.gameCardContent}>
						{gameTypeArray.map((game, index) => (
							<Grid
								data-testid="home__game_card"
								key={game.value}
								ref={
									selectedFilter !== null &&
									game.filters.includes(selectedFilter)
										? elRef
										: undefined
								}
								item
								xs={12}
								lg={6}
								sx={{
									...styles.gameCardContainerCardGridItem,
									transition: 'opacity 0.3s',
									opacity:
										selectedFilter !== null &&
										!game.filters.includes(selectedFilter)
											? '50%'
											: '100%',
									justifyContent: {
										xs: 'center',
										lg:
											index % 2 === 0
												? 'flex-start'
												: 'flex-end',
									},
								}}
							>
								<GameCard
									gameTypeEnum={game}
									comingSoonList={comingSoonList}
								/>
							</Grid>
						))}
						<Grid
							item
							xs={10}
							lg={12}
							sx={styles.gameCardContainerBaseGridItem}
						>
							<Divider
								data-testid="home__game_card_container_bottom_divider"
								sx={styles.gameCardBottomDivider}
							></Divider>
						</Grid>
					</Grid>
				</Box>

				<Grid>
					<Grid
						data-testid="home__content_lower-container"
						item
						px={6}
						sx={styles.lowerContainer}
						xs={12}
					>
						<CallToAction homeVariant data-testid="home" />
					</Grid>
					<Box sx={styles.spacer} />
				</Grid>
			</Box>
		</>
	)
}

export default HomePage
