export const styles = {
	cautionTypography: {
		color: 'black',
		opacity: 0.6,
	},
	chip: {
		paddingX: '0.5rem',
		color: 'black',
	},
	gameIdBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	ideationTypography: {
		fontSize: '1.4rem',
		letterspacing: '1px',
		marginLeft: {
			md: '5rem',
		},
		marginTop: '2.2rem',
		marginBottom: '.8rem',
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	mainBox: {
		display: 'flex',
		'.ProseMirror': {
			border: '1.5px solid var(--color-gray-4)',
		},
		'.ProseMirror-focused': {
			border: '2.5px solid var(--color-gray-4)',
		},
	},
	marginLeft: {
		xs: '0',
		md: '5red',
	},
	richTextField: {
		background: '#DCEFFE',
		borderLeft: '1.5px solid rgba(0,0,0,0.23)',
		borderRight: '1.5px solid rgba(0,0,0,0.23)',
		borderTop: '1.5px solid rgba(0,0,0,0.23)',
	},
	richTextFieldDescription: {
		marginTop: {
			md: '-1.1rem',
		},
		marginBottom: '1.5rem',
		color: 'black',
		opacity: 0.87,
	},
	richTextFieldGrid: {
		height: '25rem',
		overflow: 'hidden',
		overflowY: 'scroll',
		color: 'black',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(0deg, #cfe9ff 40%, #2b99f4 90%)',
		marginRight: '2rem',
		marginLeft: {
			md: '5rem',
		},
		marginTop: '1rem',
	},
	submitButtonBox: {
		flexDirection: {
			xs: 'column-reverse',
			sm: 'row',
		},
		display: 'flex',
		gap: '2rem',
	},
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	typographyBox: {
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		flexGrow: '1',
		paddingY: {
			sm: '2rem',
		},
	},
}
