import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Button, InputAdornment, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { BaseTextField } from 'components/Form'
import TopicChips from 'components/Hero/TopicChips'

import { typography } from 'theme/typography'

import { TopicType } from 'enums/TopicTypeEnum'

import { styles } from './Section1.styles'

export const TEST_ID = 'opposite-day-section1'

export type Props = {
	companyProblem: string
	createPrompt: () => void
	editable: boolean
	generateScenarioDisabled: () => boolean
	innovationCompany: string
	innovationTopic: TopicType
	isLoading: boolean
	resetAllField: () => void
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
	setInnovationTopic: (val: TopicType) => void
}

const Section1 = ({
	innovationCompany,
	setInnovationCompany,
	innovationTopic,
	setInnovationTopic,
	companyProblem,
	setCompanyProblem,
	generateScenarioDisabled,
	resetAllField,
	isLoading,
	createPrompt,
	editable,
}: Props) => {
	const theme = useTheme()

	return (
		<>
			<Typography sx={styles.typography}>1. INNOVATION TOPIC</Typography>
			<Box display={'flex'}>
				<Box sx={styles.sideBar} />
				<Box sx={styles.question1Box}>
					<Typography
						color="black"
						data-testid={`${TEST_ID}__company-question`}
					>
						What company are you brainstorming about today?{' '}
						<Box component="span" sx={typography.asterisk}>
							*
						</Box>
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
							required: false,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-name-input`}
						label="Enter company name here."
						value={innovationCompany}
						onChangeValue={(val) => setInnovationCompany(val)}
						required
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							...styles.enterCompany,
							...styles.textField,
						}}
					/>
					<Typography
						color="black"
						data-testid={`${TEST_ID}__business-topic-question`}
					>
						What business topic do you want to focus on?{' '}
						<Box component="span" sx={typography.asterisk}>
							*
						</Box>
					</Typography>
					<TopicChips
						color={
							theme.palette.chipColor.oppositeDay.main as string
						}
						innovationTopic={innovationTopic}
						setInnovationTopic={setInnovationTopic}
						editable={editable}
					/>
					<Typography
						color="black"
						sx={styles.summarizeBusinessProblem}
						data-testid={`${TEST_ID}__business-problem-question`}
					>
						Summarize the business problem you want to focus on (in
						5-10 words).{' '}
						<Box component="span" sx={typography.asterisk}>
							*
						</Box>
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-problem-input`}
						label="Summarize in 5-10 words"
						onChangeValue={(val) => setCompanyProblem(val)}
						value={companyProblem}
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							...styles.enterProblem,
							...styles.textField,
						}}
					/>
					<Box display="flex" sx={styles.buttonFlex} gap="2rem">
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generateScenarioDisabled() || isLoading}
							onClick={createPrompt}
						>
							START IDEATING
						</Button>
						<Button
							disabled={!editable}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAllField}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section1
