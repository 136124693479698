// Routes
const HOME_PAGE = '/'
const GAMES_PAGE = '/games'
const ACCOUNT_PAGE = '/account'
const SLOT_MACHINE = 'slot-machine'
const SIMON_SAYS = 'simon-says'
const DRESS_UP = 'dress-up'
const BATTLE_SHIPS = 'battle-ships'
const MUSICAL_CHAIRS = 'musical-chairs'
const SUPER_POWER = 'super-power'
const SAVED_IDEAS_PAGE = 'saved-ideas'
const SAVED_GAME_PAGE = 'saved-game'
const SLALOM_LOGO_LINK = 'https://go.slalom.com/arc'
const ERRORS_PAGE = 'page-not-found'
const EXPIRED_PAGE = '/expired'
const FAQ_LINK = 'https://go.slalom.com/brainstorm-faq'
const NEW_FORMAT = '/new-format'
const SUBMITTED_IDEAS = '/submitted-ideas'
const PUBLISHED_GAMES = '/published-games'
const PUBLISHED_GAME_ROUTE_TEMPLATE = `${PUBLISHED_GAMES}/:gameId`
const CUSTOM_GAMES = '/custom-games'
const CUSTOM_GAME_ROUTE_TEMPLATE = `${CUSTOM_GAMES}/:gameId`
const LEGAL_PAGE = '/legal'
const PRIVACY_POLICY = LEGAL_PAGE
const CONNECT_FOUR = 'connect-four'
const LEAP_FROG = 'leap-frog'
const ROUND_ROBIN = 'round-robin'
const LOGGED_OUT_PAGE = '/LoggedOutPage'
const PROFILE_PAGE = '/profile'
const TEETER_TOTTER = 'teeter-totter'
const OPPOSITE_DAY = 'opposite-day'
const BUILDING_BLOCKS = 'building-blocks'

const ARC_LABS = 'https://go.slalom.com/arc'

export {
	ACCOUNT_PAGE,
	ARC_LABS,
	BATTLE_SHIPS,
	CONNECT_FOUR,
	DRESS_UP,
	ERRORS_PAGE,
	EXPIRED_PAGE,
	FAQ_LINK,
	GAMES_PAGE,
	HOME_PAGE,
	LOGGED_OUT_PAGE,
	LEAP_FROG,
	MUSICAL_CHAIRS,
	NEW_FORMAT,
	PRIVACY_POLICY,
	PUBLISHED_GAME_ROUTE_TEMPLATE,
	PUBLISHED_GAMES,
	CUSTOM_GAMES,
	CUSTOM_GAME_ROUTE_TEMPLATE,
	ROUND_ROBIN,
	SAVED_GAME_PAGE,
	SAVED_IDEAS_PAGE,
	SLALOM_LOGO_LINK,
	SLOT_MACHINE,
	SIMON_SAYS,
	SUBMITTED_IDEAS,
	SUPER_POWER,
	LEGAL_PAGE,
	PROFILE_PAGE,
	TEETER_TOTTER,
	OPPOSITE_DAY,
	BUILDING_BLOCKS,
}
