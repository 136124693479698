import React, { useState } from 'react'

import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useToastContext } from 'contexts/ToastProvider'

import PromptCarouselComponent from 'pages/RoundRobin/components/Carousel'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import InfoPopper from 'components/InfoPopper/InfoPopper'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/Pencil.svg'
import arrowDown from 'assets/images/arrowDown.svg'
import arrowUp from 'assets/images/arrowUp.svg'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section2.styles'

export const TEST_ID = 'simon-says-section2'

type BulletItem = {
	details: string
	title: string
}
type PopperContent = {
	bulletItems: BulletItem[]
	header: string
	introText: string
}
export type Props = {
	createPrompt: () => void
	currentResponses: string[]
	customer: string
	generateAiImage: () => Promise<void>
	generateImageDisabled: () => boolean
	imageAvailable: boolean
	imageData: string | null
	isAiImageLoading: boolean
	isCurrentResponseLoading: boolean
	isRandomLoading: boolean
	loading: boolean
	manualChangeCustomerPrompts: (newCustomer: string) => void
	popperContent: PopperContent
	randomElement: string
	refreshRandom: () => void
	resetImage: () => void
}

const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [businessComparisonIndex, setBusinessComparisonIndex] = useState(0)
	const [showAIexampleIdea, setShowAIexampleIdea] = useState(false)
	const [editPromptsMode, setEditPromptsMode] = useState(false)
	const [
		temporalCustomerEditPromptsMode,
		setTemporalCustomerEditPromptsMode,
	] = useState<string>('')

	const onClickRefresh = () => {
		props.createPrompt()
	}
	const [temporalPromptsEditMode, setTemporalPrompts] = useState<string[]>([])
	const setPromptValue = (value: string) => {
		const newTemporalPrompts = [...temporalPromptsEditMode]
		newTemporalPrompts[businessComparisonIndex] = value
		setTemporalPrompts(newTemporalPrompts)
	}

	const onClickEdit = () => {
		setTemporalCustomerEditPromptsMode(props.customer)
		setEditPromptsMode(true)
	}

	const onClickCancelEdit = () => {
		setEditPromptsMode(false)
	}

	const onClickSaveEdit = () => {
		props.manualChangeCustomerPrompts(temporalCustomerEditPromptsMode)
		setShowAIexampleIdea(false)
		setEditPromptsMode(false)
		if (showAlert) {
			showAlert({
				objectType: 'editPrompt',
				severity: ToastSeverity.SUCCESS,
			})
		}
	}
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const handleClickInfoIcon = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	// remove after backend is complete
	const temporaryFacilitationSteps = {
		data: {
			personas: [
				{ name: 'RANDOM PERSONA 1', traits: 'Random persona 1 traits' },
				{ name: 'RANDOM PERSONA 2', traits: 'Random persona 2 traits' },
				{ name: 'RANDOM PERSONA 3', traits: 'Random persona 3 traits' },
				{ name: 'RANDOM PERSONA 4', traits: 'Random persona 4 traits' },
			],
		},
	}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
					paddingY={{ xs: '0', sm: '1rem' }}
				>
					<Box
						display={'flex'}
						flexDirection={{
							xs: 'column',
							sm: 'column',
						}}
						justifyContent={'space-between'}
						gap={'1rem'}
					>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							alignItems={{
								xs: 'flex-start',
								sm: 'center',
							}}
							gap={'1rem'}
							padding={0}
						>
							<Typography
								data-testid={`${TEST_ID}__intro-text`}
								color="black"
								sx={{ opacity: 0.87 }}
								fontSize={{
									xs: 16,
									sm: 20,
								}}
								gap={'0.5rem'}
								maxWidth={{
									sm: '488px',
									md: '600px',
									lg: '940px',
								}}
							>
								How could you solve the innovation topic
								specifically for the following customer, even if
								they don’t relate to your normal business
								operations?
								<InfoPopper
									handleClick={handleClickInfoIcon}
									anchorEl={anchorEl}
									popperContent={props.popperContent}
								/>
							</Typography>
							<Box
								display={'inline-flex'}
								flexDirection={'row'}
								justifyContent={'flex-end'}
								alignItems={'center'}
								gap={'0.5rem'}
								padding={'6px 8px'}
							>
								<ButtonWithIconComponent
									sx={{ padding: 0 }}
									disabled={props.loading}
									Icon={<RefreshIcon fontSize="small" />}
									text="REFRESH"
									data-testid={`${TEST_ID}__refresh-button`}
									onClickAction={onClickRefresh}
									iconWidth={'13px'}
								/>
							</Box>
						</Box>
						<Box sx={styles.carouselWrapper}>
							<Box
								flexDirection={{
									xs: 'column',
									sm: 'row',
								}}
								className="button-wrapper"
								data-testid={`${TEST_ID}__business-comparison-buttons`}
							>
								{temporaryFacilitationSteps.data.personas.map(
									(item, index) => (
										<React.Fragment key={index}>
											<Button
												data-testid={`${TEST_ID}__business-comparison-${index}`}
												className={
													index ===
													businessComparisonIndex
														? 'selected'
														: ''
												}
												onClick={() =>
													setBusinessComparisonIndex(
														index
													)
												}
												disabled={editPromptsMode}
											>
												{item.name}
											</Button>
											{index <
												temporaryFacilitationSteps.data
													.personas.length -
													1 && (
												<Divider
													orientation="vertical"
													variant="middle"
													flexItem={true}
												/>
											)}
										</React.Fragment>
									)
								)}
							</Box>
						</Box>
					</Box>
					<Box data-testid={`${TEST_ID}__business-comparison-traits`}>
						<PromptCarouselComponent
							disabledArrows={editPromptsMode}
							setCurrentIndex={setBusinessComparisonIndex}
							currentIndex={businessComparisonIndex}
							currentPrompts={temporaryFacilitationSteps.data.personas.map(
								(item) => item.traits
							)}
							promptsEditMode={temporalPromptsEditMode}
							editable={editPromptsMode}
							setPromptValue={setPromptValue}
							names={temporaryFacilitationSteps.data.personas.map(
								(item) => item.name
							)}
							sxCardOverrides={{
								backgroundColor: 'rgba(237, 231, 246, 0.8)',
							}}
						/>
					</Box>
					{editPromptsMode ? (
						<Box
							display="flex"
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							gap="2rem"
						>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={onClickSaveEdit}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEdit}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Box>
					) : (
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'end'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT"
								data-testid={`${TEST_ID}__edit-button`}
								onClickAction={onClickEdit}
							/>
						</Box>
					)}
					{!!props.currentResponses && (
						<>
							<Typography
								color="black"
								sx={{ opacity: 0.87 }}
								fontSize={{
									xs: 16,
									sm: 20,
								}}
							>
								{brainstormText.global.ideasDropdown}
							</Typography>
							<Box>
								<Box borderBottom={2} borderColor={'#D6D4FF'}>
									<Button
										fullWidth
										color="inherit"
										data-testid={`${TEST_ID}-show-ai-example-button`}
										onClick={() =>
											setShowAIexampleIdea(
												!showAIexampleIdea
											)
										}
									>
										<Box
											display={'flex'}
											justifyContent="space-between"
											width="100%"
										>
											<Typography
												color="black"
												sx={{ opacity: 0.87 }}
												fontSize={12}
												lineHeight={'266%'}
											>
												View AI-Generated Example Ideas
											</Typography>
											<img
												alt="arrow"
												data-testid={`${TEST_ID}-arrow`}
												src={
													(showAIexampleIdea
														? arrowUp
														: arrowDown) as unknown as string
												}
											/>
										</Box>
									</Button>
								</Box>
								{showAIexampleIdea && (
									<Box
										bgcolor={'rgba(237, 231, 246, 0.8)'}
										padding={'1rem'}
									>
										{props.isCurrentResponseLoading && (
											<CircularProgress
												color="secondary"
												sx={
													commonStyles.circularProgress
												}
											/>
										)}
										<ol data-testid="gen-ai-response-list">
											{props.currentResponses.map(
												(el: string, i: number) => (
													<Typography
														fontSize={16}
														color="black"
														component="li"
														key={`ai-solution-${i}`}
														sx={{ opacity: 0.87 }}
														variant="body1"
														style={{
															margin: '10px 0 10px 0',
														}}
													>
														{el}
													</Typography>
												)
											)}
										</ol>
									</Box>
								)}
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	)
}
export default Section2
