export const styles = {
	outerContainer: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '3rem',
		flexGrow: 1,
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	instructionContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}
