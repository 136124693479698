import { useState } from 'react'

import { Box, Button, TextField, Typography } from '@mui/material'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import PromptBox from 'components/PromptBoxV2'
import Section2Title from 'components/Section2Title'
import UserEducationModal from 'components/UserEducationModal'

import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './PromptSection.styles'

export const TEST_ID = 'battle-ships-prompt-section'

export type Props = {
	generateNewScenarioDisabled: () => boolean
	handleClick: () => void
	isLoading: boolean
	prompt: string
	reloadIdeas: (newPrompt: string) => Promise<void>
}

const PromptSection = ({
	prompt,
	isLoading,
	generateNewScenarioDisabled,
	handleClick,
	reloadIdeas,
}: Props) => {
	const [isEditMode, setIsEditMode] = useState(false)

	const handleSaveButtonClick = async () => {
		const textareaPrompt = document.getElementsByTagName('textarea')

		if (textareaPrompt && textareaPrompt.length > 0) {
			setIsEditMode(false)
			await reloadIdeas(textareaPrompt[0].value)
		}
	}

	return (
		<>
			<Section2Title />
			<UserEducationModal />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					flexGrow="1"
					gap="1rem"
				>
					<Box
						display={'flex'}
						flexDirection={'column'}
						rowGap={'3rem'}
						flexGrow="1"
					>
						<Box
							display="flex"
							justifyContent="space-between"
							sx={{
								flexDirection: {
									lg: 'row',
									md: 'row',
									sm: 'row',
									xs: 'column',
								},
								alignItems: {
									lg: 'center',
									md: 'center',
									sm: 'center',
									xs: 'flex-start',
								},
							}}
						>
							<Typography sx={commonStyles.header}>
								Imagine your company experiencing the following
								unexpected disruption.
							</Typography>
							<ButtonWithIconComponent
								disabled={
									generateNewScenarioDisabled() || isLoading
								}
								iconSvg={refreshIcon}
								text="REFRESH"
								data-testid={`${TEST_ID}__prompt-box_main-refresh`}
								onClickAction={handleClick}
							/>
						</Box>
					</Box>
					{isEditMode ? (
						<>
							<TextField
								data-testid={`${TEST_ID}__edit-company-disruption`}
								defaultValue={prompt}
								multiline
								fullWidth
								inputRef={(input) => input && input.focus()}
							/>

							<Box
								display="flex"
								flexDirection={{
									xs: 'column',
									sm: 'row',
								}}
								gap="2rem"
							>
								<Button
									color="secondary"
									variant="contained"
									data-testid={`${TEST_ID}__edit-save-button`}
									onClick={async () =>
										await handleSaveButtonClick()
									}
								>
									<Typography>SAVE CHANGES</Typography>
								</Button>
								<Button
									variant="text"
									color="secondary"
									data-testid={`${TEST_ID}__edit-cancel-button`}
									onClick={() => null}
								>
									<Typography>CANCEL</Typography>
								</Button>
							</Box>
						</>
					) : (
						<>
							<PromptBox
								gameTypeEnum={GAME_TYPE.BATTLE_SHIPS}
								contentText={
									prompt || 'Generate Scenario Above'
								}
								data-testid="company-disruption"
								loading={isLoading}
								hidePromptContainerStyle={true}
							/>
							<Box display={'flex'} justifyContent={'flex-end'}>
								<ButtonWithIconComponent
									disabled={
										generateNewScenarioDisabled() ||
										isLoading
									}
									iconSvg={editIcon}
									text="EDIT"
									data-testid={`${TEST_ID}-edit-prompt`}
									onClickAction={() =>
										setIsEditMode(!isEditMode)
									}
								/>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</>
	)
}

export default PromptSection
