export const styles = {
	h6: { textAlign: 'center' },
	body1: { fontSize: '12px', color: 'black' },
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		maxWidth: '293px',
		margin: 'auto',
	},
	button: {
		width: '100%',
		display: 'block',
		textAlign: 'center',
		padding: '6px 16px',
	},
	dialog: {
		zIndex: 2, // This is so that the application Header (z-index = 3) is above the Dialog background
		backdropFilter: 'blur(16px)',
		width: { xs: '100%' },
		' .MuiDialog-container': {
			alignItems: { xs: 'end', md: 'center' },
			width: '100%',
		},
		' .MuiPaper-root': {
			padding: '32px',
			margin: { xs: 0 },
			maxWidth: { xs: '100%', md: '560px' },
			boxSizing: 'border-box',
			borderRadius: { xs: '16px 16px 0 0', md: '32px' },
		},
	},
	label: {
		marginBottom: '20px',
		color: 'black',
		fontSize: '16px',
	},
	imageContainer: {
		'& img': {
			margin: 'auto',
			display: 'block',
		},
	},
}
