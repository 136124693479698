import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import {
	RoundRobinResponseResponse,
	conversation,
	userMessage,
} from './ideaModels'

export function RoundRobinModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [companyName, setCompanyName] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [currentPrompts, setCurrentPrompts] = useState<string[]>(
		Array(4).fill('')
	)
	const [currentResponse, setCurrentResponse] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [roundRobinPrompt, setRoundRobinPrompt] =
		useState<RoundRobinResponseResponse>()

	const setPromptResponse = (response: RoundRobinResponseResponse) => {
		setRoundRobinPrompt(response)
	}
	const [
		promptConversationFactFinderPrompt,
		setPromptConversationFactFinderPrompt,
	] = useState<conversation[]>([])

	const [
		promptConversationOpportunityExplorerPrompt,
		setPromptConversationOpportunityExplorerPrompt,
	] = useState<conversation[]>([])

	const [
		promptConversationChallengeIdentifierPrompt,
		setPromptConversationChallengeIdentifierPrompt,
	] = useState<conversation[]>([])

	const [
		promptConversationTestingStrategistPrompt,
		setPromptConversationTestingStrategistPrompt,
	] = useState<conversation[]>([])

	const [responseConversation, setResponseConversation] = useState<
		conversation[]
	>([])

	const gameTypeId = GAME_TYPE.ROUND_ROBIN.value

	// Note: order matters for display purposes
	const ValidGameScenario = [
		'FactFinderPrompt',
		'OpportunityExplorerPrompt',
		'ChallengeIdentifierPrompt',
		'TestingStrategistPrompt',
	]

	const generatePromptsDisabled = () => {
		if (!companyName || !companyProblem) return true
		else return false
	}

	const generateNewScenarioDisabled = () => {
		if (
			generatePromptsDisabled() ||
			currentPrompts[0].length === 0 ||
			currentPrompts[1].length === 0 ||
			currentPrompts[2].length === 0 ||
			currentPrompts[3].length === 0
		) {
			return true
		}
		return false
	}

	const submitIdeasDisabled = () => {
		if (generatePromptsDisabled() || !authorIdeation) return true
		return false
	}

	const reset = () => {
		resetSoft()
		setCurrentPrompts(['', '', '', ''])
		setPromptConversationFactFinderPrompt([])
		setPromptConversationOpportunityExplorerPrompt([])
		setPromptConversationChallengeIdentifierPrompt([])
		setPromptConversationTestingStrategistPrompt([])
	}

	const resetSoft = () => {
		setAuthorIdeation('')
		setResponseConversation([])
		setCurrentResponse('')
	}

	const responseToJSON = () => {
		return {
			// PLG VALIDATE THIS ORDER
			innovationTopicManual: companyProblem,
			innovationCompany: companyName,
			conversation: responseConversation,
			factPrompt: currentPrompts[0] ?? '',
			scalePrompt: currentPrompts[1] ?? '',
			challengePrompt: currentPrompts[2] ?? '',
			testingPrompt: currentPrompts[3] ?? '',
		}
	}

	const postObject = () => {
		return {
			gameId: gameId ?? undefined,
			gameTypeId,
			innovationTopicManual: companyProblem,
			innovationCompany: companyName,
			authorIdeation,
		}
	}

	const updatePromptConversationHistory = (
		index: number,
		data: string
	): void => {
		const aiResponse = {
			content: data,
			role: 'assistant',
		}

		switch (index) {
			case 0: {
				setPromptConversationFactFinderPrompt(
					(prevMessages: conversation[]) => [
						...prevMessages,
						userMessage,
						aiResponse,
					]
				)
				break
			}
			case 1: {
				setPromptConversationOpportunityExplorerPrompt(
					(prevMessages: conversation[]) => [
						...prevMessages,
						userMessage,
						aiResponse,
					]
				)
				break
			}
			case 2: {
				setPromptConversationChallengeIdentifierPrompt(
					(prevMessages: conversation[]) => [
						...prevMessages,
						userMessage,
						aiResponse,
					]
				)
				break
			}
			case 3: {
				setPromptConversationTestingStrategistPrompt(
					(prevMessages: conversation[]) => [
						...prevMessages,
						userMessage,
						aiResponse,
					]
				)
				break
			}
			default: {
				break
			}
		}
	}

	const getPromptConversation = (index: number): conversation[] => {
		let messages: conversation[]

		switch (index) {
			case 0: {
				messages = [...promptConversationFactFinderPrompt, userMessage]
				return messages
			}
			case 1: {
				messages = [
					...promptConversationOpportunityExplorerPrompt,
					userMessage,
				]
				return messages
			}
			case 2: {
				messages = [
					...promptConversationChallengeIdentifierPrompt,
					userMessage,
				]
				return messages
			}
			case 3: {
				messages = [
					...promptConversationTestingStrategistPrompt,
					userMessage,
				]
				return messages
			}
			default: {
				return [userMessage]
			}
		}
	}

	const promptToJSON = (index: number) => {
		return {
			innovationTopicManual: companyProblem,
			innovationCompany: companyName,
			conversation: getPromptConversation(index),
		}
	}

	const buttonDisabled = () => {
		if (
			authorIdeation.length === 0 ||
			companyName.length === 0 ||
			companyProblem.length === 0 ||
			!currentPromptsValid()
		) {
			return true
		}
		return false
	}

	const currentPromptsValid = () => {
		if (
			currentPrompts[0].length === 0 ||
			currentPrompts[1].length === 0 ||
			currentPrompts[2].length === 0 ||
			currentPrompts[3].length === 0
		) {
			return false
		}
		return true
	}

	const isAiExampleVisible = () => {
		if (!currentResponse.length) {
			return false
		}
		return true
	}

	return {
		companyName,
		companyProblem,
		setCompanyName,
		setCompanyProblem,
		generatePromptsDisabled,
		buttonDisabled,
		reset,
		resetSoft,
		responseConversation,
		setResponseConversation,
		currentResponse,
		setCurrentResponse,
		promptToJSON,
		setGameId,
		authorIdeation,
		setAuthorIdeation,
		responseToJSON,
		currentPrompts,
		setCurrentPrompts,
		ValidGameScenario,
		postObject,
		updatePromptConversationHistory,
		generateNewScenarioDisabled,
		submitIdeasDisabled,
		isAiExampleVisible,
		gameId,
		currentPromptsValid,
		setPromptResponse,
		roundRobinPrompt,
	}
}
