import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Container } from '@mui/material'
import logger from 'utils/logger'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { RoundRobinModel } from 'models/RoundRobinModel'
import { Game } from 'models/gameModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import {
	postRoundRobinPrompt,
	postRoundRobinResponse,
} from 'services/completions.service'
import { postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'
import { IdeationRecommendationModal } from 'components/IdeationRecommendationModal/IdeationRecommendationModal'

import { alertTitleTextMap, defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { SUBMITTED_IDEAS } from 'assets/routes'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import Hero from '../../components/Hero/Hero'
import Section1 from './components/Section1/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'

export const TEST_ID = 'dress-up'

const userMessage = {
	content: '',
	role: 'user',
}

const RoundRobin = ({ game }: { game?: Game }) => {
	const [submitIdeas, setSubmitIdeas] = useState(false)
	const [ideaPrompt, setIdeaPrompt] = useState(false)
	const [refreshClicksSinceIdeation, setRefreshClicksSinceIdeation] =
		useState(0)

	const {
		authorIdeation,
		reset,
		resetSoft,
		setAuthorIdeation,
		companyName,
		companyProblem,
		generatePromptsDisabled,
		setCompanyName,
		setCompanyProblem,
		ValidGameScenario,
		promptToJSON,
		setCurrentPrompts,
		updatePromptConversationHistory,
		postObject,
		currentResponse,
		currentPrompts,
		responseConversation,
		submitIdeasDisabled,
		responseToJSON,
		setResponseConversation,
		setCurrentResponse,
		setGameId,
		roundRobinPrompt,
		setPromptResponse,
	} = RoundRobinModel()

	useEffect(() => {
		if (game) {
			setGameId(game.gameId)
			setCompanyName(game.innovationCompany)
			setCompanyProblem(game.companyProblem)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		setIdeaPrompt(authorIdeation.length >= 8)
	}, [authorIdeation])

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	useEffect(() => {
		handleAiResponseClick()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPrompts, setCurrentPrompts])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (ideaPrompt) {
				event.preventDefault()
			}
		}

		if (ideaPrompt) {
			window.addEventListener('beforeunload', handleBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [ideaPrompt])

	const { setIdeaStreakCount } = useIdeaStreakContext()
	const { showAlert } = useToastContext()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const [isAiLoading, setIsAiLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)

	const updateAIResponse = (content: string) => {
		return {
			content,
			role: 'assistant',
		}
	}
	const resetAllField = () => {
		setCompanyProblem('')
		setCompanyName('')
		setCompanyProblem('')
	}

	const viewSubmittedIdeas = () => {
		window.scrollTo(0, 0)
		navigate(SUBMITTED_IDEAS)
	}

	const refreshPrompt = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			!!authorIdeation.length
		) {
			return ShowWarningModal(
				{
					state: 'blocked',
					location: {
						pathname: '',
						search: '',
						hash: '',
						state: null,
						key: 'kxdgy6c2',
					},
					reset: () => logger.debug('reset'),
					proceed: () => handlePrompts(),
				},
				showModal
			)
		}

		// Only generate new prompts if user has ideated since last 3 refreshes
		if (refreshClicksSinceIdeation < 2) {
			handlePrompts()
		}

		setRefreshClicksSinceIdeation(refreshClicksSinceIdeation + 1)
	}

	const handlePrompts = async () => {
		try {
			resetSoft()
			setIsLoading(true)
			setStep(GAME_STEP.Step2)
			// need to get the conversation for each game
			const [
				factFinderResponse,
				opportunityExplorerPrompt,
				challengeIdentifyResponse,
				testingStrategistPrompt,
			] = await Promise.all([
				postRoundRobinPrompt(promptToJSON(0), ValidGameScenario[0]),
				postRoundRobinPrompt(promptToJSON(1), ValidGameScenario[1]),
				postRoundRobinPrompt(promptToJSON(2), ValidGameScenario[2]),
				postRoundRobinPrompt(promptToJSON(3), ValidGameScenario[3]),
			])

			// make sure everything is ok
			if (
				factFinderResponse.status === 200 &&
				opportunityExplorerPrompt.status === 200 &&
				challengeIdentifyResponse.status === 200 &&
				testingStrategistPrompt.status === 200
			) {
				// set the current prompts
				setCurrentPrompts([
					factFinderResponse.data,
					challengeIdentifyResponse.data,
					testingStrategistPrompt.data,
					opportunityExplorerPrompt.data,
				])

				setPromptResponse({
					data: {
						ideas: [
							factFinderResponse.data,
							challengeIdentifyResponse.data,
							testingStrategistPrompt.data,
							opportunityExplorerPrompt.data,
						],
					},
					status: testingStrategistPrompt.status,
				})

				// update history
				updatePromptConversationHistory(0, factFinderResponse.data)
				updatePromptConversationHistory(
					1,
					opportunityExplorerPrompt.data
				)
				updatePromptConversationHistory(
					2,
					challengeIdentifyResponse.data
				)
				updatePromptConversationHistory(3, testingStrategistPrompt.data)

				await handleAiResponseClick()
			} else {
				throw Error('Not all responses received successfully')
			}
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleManualPromptChange = async (newValues: string[]) => {
		try {
			resetSoft()

			setCurrentPrompts([
				newValues[0],
				newValues[1],
				newValues[2],
				newValues[3],
			])

			// update history
			updatePromptConversationHistory(0, newValues[0])
			updatePromptConversationHistory(1, newValues[1])
			updatePromptConversationHistory(2, newValues[2])
			updatePromptConversationHistory(3, newValues[3])

			await handleAiResponseClick()
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(postObject())

			if (showAlert) {
				showAlert({
					title:
						response.status === 200
							? alertTitleTextMap.submitIdea.title
							: alertTitleTextMap.submitIdeaError.title,
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
			setIdeaStreakCount((prevState) => prevState + 1)
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)

			// need to reset the model
			reset()

			window.scrollTo({
				top: 0,
			})
			setStep(GAME_STEP.Step1)
		}
	}

	const handleAiResponseClick = async () => {
		const prompt = responseToJSON()
		prompt.conversation = [...responseConversation, userMessage]

		try {
			const response = await postRoundRobinResponse(prompt)

			if (response.status === 200 && response.data) {
				const msg = response.data.ideas.join('\n')

				if (msg) {
					// add response to state
					setResponseConversation((prevMessages) => [
						...prevMessages,
						userMessage,
						updateAIResponse(msg),
					])

					setCurrentResponse(msg)
				} else {
					if (showAlert) {
						showAlert(defaultErrorMessage)
					}
				}
			} else {
				throw Error(`response received ${response}`)
			}
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsAiLoading(false)
		}
	}

	const onIdeationRecommendationBackClicked = () => {
		setStep(GAME_STEP.Step1)
		onIdeationRecommendationModalClosed()
	}

	const onIdeationRecommendationModalClosed = () => {
		setRefreshClicksSinceIdeation(0)
	}

	useEffect(() => {
		setRefreshClicksSinceIdeation(0)
	}, [authorIdeation])

	return (
		<Box>
			<IdeationRecommendationModal
				refreshClicksSinceIdeation={refreshClicksSinceIdeation}
				onBack={onIdeationRecommendationBackClicked}
				onClose={onIdeationRecommendationModalClosed}
			/>
			<Hero
				data-testid="innovation-modal"
				step={step}
				gameTypeEnum={GAME_TYPE.ROUND_ROBIN}
				innovationCompany={companyName}
				companyProblem={companyProblem}
				setInnovationCompany={setCompanyName}
				setCompanyProblem={setCompanyProblem}
				isLoading={isLoading}
				createPrompt={handlePrompts}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth="md"
				sx={{
					paddingTop: '3rem',
					paddingBottom: '3rem',
					paddingX: '3rem',
				}}
			>
				<Box paddingBottom={'2rem'}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === GAME_STEP.Step1,
								onClick:
									step !== GAME_STEP.Step1
										? () => setStep(GAME_STEP.Step1)
										: undefined,
							},
							{
								label: '2. FACILITATION TECHNIQUE & 3. IDEATION',
								active: step !== GAME_STEP.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<Section1
						isLoading={isLoading || isAiLoading}
						companyName={companyName}
						setCompanyName={setCompanyName}
						companyIdea={companyProblem}
						setCompanyIdea={setCompanyProblem}
						generatePromptsDisabled={generatePromptsDisabled}
						resetAllField={resetAllField}
						createPrompt={handlePrompts}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
						navigateTo={(path: string) =>
							window.open(
								`${window.location.origin}${path}`,
								'_blank'
							)
						}
					/>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<Section2
							loading={
								isLoading ||
								isAiLoading ||
								isSubmitButtonLoading
							}
							currentResponse={currentResponse}
							currentPrompts={currentPrompts}
							createPrompt={refreshPrompt}
							changePrompts={handleManualPromptChange}
							roundRobinPrompt={roundRobinPrompt}
						/>
						<Section3
							submitIdeasDisabled={submitIdeasDisabled}
							loading={
								isLoading ||
								isAiLoading ||
								isSubmitButtonLoading
							}
							submitIdeas={handleSubmitIdeas}
							authorIdeation={authorIdeation}
							setAuthorIdeation={setAuthorIdeation}
							viewSubmittedIdeas={viewSubmittedIdeas}
							cautionText={
								game?.gameTitle || game?.endTime
									? brainstormText.global.alternateWarningText
									: brainstormText.global.warningText
							}
						/>
					</>
				)}
			</Container>
		</Box>
	)
}

export default RoundRobin
