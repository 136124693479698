export const styles = {
	editButtonBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
	},
	editPromptsBox: {
		display: 'flex',
		gap: '2rem',
	},
	editPromptsMode: {
		'.MuiInputBase-input': {
			fontWeight: '500',
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: {
				xs: 16,
				sm: 20,
			},
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.MuiOutlinedInput-root': {
			borderRadius: '0',
			borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
			backgroundColor: '#FFFFFF',
		},
		flexDirection: {
			xs: 'column',
			sm: 'row',
		},
	},
	mainBox: {
		display: 'flex',
	},
	marginRightBox: {
		marginRight: '2rem',
	},
	paper: {
		padding: '2rem',
		backgroundColor: '#DCEFFE',
		height: '21rem',
		overflow: 'hidden',
		overflowY: 'scroll',
		color: 'black',
	},
	refreshButtonBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end',
		alignItems: 'center',
		gap: '1rem',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(0deg, #cfe9ff 40%, #2b99f4 90%)',
		marginRight: '2rem',
	},
	typography: {
		opacity: 0.87,
		color: 'black',
	},
	typographyBox: {
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		flexGrow: '1',
	},
}
