export type GameName =
	| 'DRESS_UP'
	| 'SIMON_SAYS'
	| 'BATTLE_SHIPS'
	| 'LEAP_FROG'
	| 'ROUND_ROBIN'
	| 'MUSICAL_CHAIRS'
	| 'SLOT_MACHINE'
	| 'CONNECT_FOUR'
	| 'TEETER_TOTTER'
	| 'OPPOSITE_DAY'

interface FlameGradient {
	fromColor: string
	toColor: string
}
type FlameThemeVariant = {
	[index in GameName]: FlameGradient
}

const flameVariants: FlameThemeVariant = {
	DRESS_UP: { fromColor: '#72a4ef', toColor: '#12b9f1' },
	SIMON_SAYS: { fromColor: '#0400e6', toColor: '#7523f9' },
	BATTLE_SHIPS: { fromColor: '#e65c00', toColor: '#f9d423' },
	LEAP_FROG: { fromColor: '#3e9d12', toColor: '#d1f2a7' },
	ROUND_ROBIN: { fromColor: '#ec008c', toColor: '#fc6767' },
	MUSICAL_CHAIRS: { fromColor: '#0e94a6', toColor: '#90dae3' },
	// TODO: add colors to flame once the games are enabled
	SLOT_MACHINE: { fromColor: '', toColor: '' },
	CONNECT_FOUR: { fromColor: '', toColor: '' },
	TEETER_TOTTER: { fromColor: '#ff001e', toColor: '#ffa1bd' },
	OPPOSITE_DAY: { fromColor: '#3498e9', toColor: '#bde2ff' },
}

interface FlameIgnitedProps {
	title: string
	variant: GameName
}
const FlameIgnited = ({ variant, title }: FlameIgnitedProps) => {
	const { fromColor, toColor } = flameVariants[variant]
	return (
		<svg
			width="22"
			height="24"
			viewBox="0 0 22 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{title}</title>
			<path
				d="M16.125 5.41263L15.5338 6.08812C14.9694 6.72676 14.2169 7.00924 13.4644 7.00924C12.0938 7.00924 10.75 6.05128 10.75 4.55292V0.5C10.75 0.5 0 5.41263 0 14.0097C0 19.4382 4.81063 23.835 10.75 23.835C16.6894 23.835 21.5 19.4382 21.5 14.0097C21.5 10.3744 19.3366 7.10749 16.125 5.41263ZM10.75 21.3787C9.27188 21.3787 8.0625 20.3102 8.0625 18.9961C8.0625 18.3697 8.33125 17.7802 8.84188 17.3258L10.75 15.6064L12.6716 17.3258C13.1688 17.7802 13.4375 18.3697 13.4375 18.9961C13.4375 20.3102 12.2281 21.3787 10.75 21.3787ZM16.0713 19.5365C16.125 19.0943 16.3669 17.2152 14.5528 15.5818L10.75 12.1675L6.94719 15.5818C5.11969 17.2275 5.375 19.1189 5.42875 19.5365C3.74906 18.1855 2.6875 16.2081 2.6875 14.0097C2.6875 10.1288 5.54969 7.07065 8.10281 5.10559C8.41187 7.54963 10.6963 9.46556 13.4644 9.46556C14.5125 9.46556 15.5338 9.18308 16.3938 8.65497C17.9256 10.0551 18.8125 11.9833 18.8125 14.0097C18.8125 16.2081 17.7509 18.1855 16.0713 19.5365Z"
				fill="url(#paint0_linear_8278_36923)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_8278_36923"
					x1="10.7567"
					y1="0.5"
					x2="10.7567"
					y2="23.835"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={fromColor} />
					<stop offset="1" stopColor={toColor} stopOpacity="0.51" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default FlameIgnited
