import { useEffect, useState } from 'react'

import { Box, Container } from '@mui/material'

import { BuildingBlocksModel } from 'models/BuildingBlocksModel'
import { Game } from 'models/gameModels'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { BusinessTopicType } from 'enums/TopicTypeEnum'

import Hero from '../../components/Hero/Hero'
import { styles } from './BuildingBlocks.styles'
import Section1 from './components/Section1/Section1'

export const TEST_ID = 'building-blocks'
const BuildingBlocks = ({ game }: { game?: Game }) => {
	useEffect(() => {
		if (game) {
			setInnovationCompany(game.innovationCompany)
			setInnovationTopic(game.innovationTopic)
			setCompanyProblem(game.companyProblem)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const {
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		innovationTopic,
		setInnovationTopic,
		setGameId,
		generateScenarioDisabled,
	} = BuildingBlocksModel()

	const [isLoading, setIsLoading] = useState(false)
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)

	const resetAllField = () => {
		setInnovationCompany('')
		setInnovationTopic('')
		setCompanyProblem('')
	}
	const createPrompt = async () => {
		try {
			setIsLoading(true)
			setStep(GAME_STEP.Step2)
			window.scrollTo(0, 0)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.BUILDING_BLOCKS}
				innovationTopic={innovationTopic as BusinessTopicType}
				innovationCompany={innovationCompany}
				companyProblem={companyProblem}
				isLoading={isLoading}
				setCompanyProblem={setCompanyProblem}
				setInnovationCompany={setInnovationCompany}
				setInnovationTopic={setInnovationTopic}
				createPrompt={createPrompt}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth={step === GAME_STEP.Step1 ? 'md' : 'lg'}
				sx={styles.container}
			>
				<Box sx={styles.containerBox}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === GAME_STEP.Step1,
								onClick:
									step !== GAME_STEP.Step1
										? () => setStep(GAME_STEP.Step1)
										: undefined,
							},
							{
								label: '2. FACILITATION TECHNIQUE & 3. IDEATION',
								active: step !== GAME_STEP.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<Section1
						isLoading={isLoading}
						innovationCompany={innovationCompany}
						setInnovationCompany={setInnovationCompany}
						innovationTopic={innovationTopic as BusinessTopicType}
						setInnovationTopic={setInnovationTopic}
						companyProblem={companyProblem}
						setCompanyProblem={setCompanyProblem}
						generateScenarioDisabled={generateScenarioDisabled}
						resetAllField={resetAllField}
						createPrompt={createPrompt}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
					/>
				)}
			</Container>
		</Box>
	)
}
export default BuildingBlocks
