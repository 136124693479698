import React, { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import {
	Button,
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material'

import { useToastContext } from 'contexts/ToastProvider'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'

import { brainstormText } from 'assets/brainstormText'
import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section2.styles'

export const TEST_ID = 'musical-chairs-section2'

export type Props = {
	businessComparisons: string[]
	businessComparisonsHasChanged: boolean[]
	createPrompt: () => void
	currentResponses: string[]
	loading: boolean
	musicalChairsPrompt: string[][]
	reloadIdeas: () => Promise<void>
}

const buildResponseItem = (solution?: string): React.ReactElement => {
	if (!solution) {
		return (
			<Grid sx={styles.responseContainer}>
				<Typography
					variant="body1"
					sx={{ ...styles.responseText }}
					data-testid={`${TEST_ID}__no-response`}
				>
					No response available
				</Typography>
			</Grid>
		)
	}

	const stringSol = solution
		.split(/(\d.)/)
		?.splice(1)
		.filter((x) => isNaN(parseInt(x)))

	if (!stringSol?.length) {
		return (
			<Grid sx={styles.responseContainer}>
				<Typography
					variant="body1"
					sx={{ ...styles.responseText }}
					data-testid={`${TEST_ID}__no-numbered-items`}
				>
					No numbered items found
				</Typography>
			</Grid>
		)
	}

	return (
		<Grid sx={styles.responseContainer}>
			<ol data-testid="gen-ai-response-list">
				{stringSol.map((el: string, i: number) => (
					<Typography
						component="li"
						key={`ai-solution-${i}`}
						variant="body1"
						sx={{ ...styles.responseText, ...styles.listItem }}
					>
						{el}
					</Typography>
				))}
			</ol>
		</Grid>
	)
}

const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [businessComparisonIndex, setBusinessComparisonIndex] = useState(
		Math.floor(Math.random() * 4)
	)
	const [editPromptMode, setEditPromptMode] = useState(false)
	const [isGenAiLoading, setIsGenAiLoading] = useState(false)
	const [processStage1, setProcessStage1] = useState<string[]>([])
	const [isFirstProcessSelected, setFirstProcessSelection] = useState(false)
	const [addStageCount, setAddStageCount] = useState(0)

	const [stageValue, setStageValue] = React.useState('eliminate')
	const handleStageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStageValue((event.target as HTMLInputElement).value)
	}

	const onClickBusinessComparison = (index: number) => {
		if (editPromptMode) {
			const editedIdea = document.getElementsByTagName('textarea')
			if (editedIdea && editedIdea.length >= 1) {
				props.musicalChairsPrompt[businessComparisonIndex][1] =
					editedIdea[0].value
			}
		}
		setBusinessComparisonIndex(index)
		if (stageValue === 'swap') {
			if (!isFirstProcessSelected) {
				setProcessStage1(props.musicalChairsPrompt[index])
				setFirstProcessSelection(true)
			} else {
				props.musicalChairsPrompt[businessComparisonIndex] =
					props.musicalChairsPrompt[index]
				props.musicalChairsPrompt[index] = processStage1
				setFirstProcessSelection(false)
			}
		}
	}

	const onClickRefresh = () => {
		if (stageValue === 'eliminate') {
			props.musicalChairsPrompt.splice(businessComparisonIndex, 1)
			setBusinessComparisonIndex(Math.floor(Math.random() * 4))
		} else {
			const randomNumber1 = Math.floor(Math.random() * 4)
			const randomNumber2 = Math.floor(Math.random() * 4)
			const placeholderPrompt = props.musicalChairsPrompt[randomNumber1]
			props.musicalChairsPrompt[randomNumber1] =
				props.musicalChairsPrompt[randomNumber2]
			props.musicalChairsPrompt[randomNumber2] = placeholderPrompt
			setBusinessComparisonIndex(Math.floor(Math.random() * 4))
		}
	}

	const onClickEditPrompt = () => {
		setEditPromptMode(true)
	}

	const handleExitAddStage = () => {
		const size = props.musicalChairsPrompt.length
		if (size > 4) {
			for (let i = 1; i <= size - 4; i++) {
				const currentIndex = size - i
				const stage = props.musicalChairsPrompt[currentIndex][0]
				const value = props.musicalChairsPrompt[currentIndex][1]
				if (parseInt(stage) > 4 && value === '') {
					props.musicalChairsPrompt.splice(currentIndex, 1)
				}
			}
		}
		props.musicalChairsPrompt.map(
			(prompt, i) => (prompt[0] = (i + 1).toString())
		)
		setAddStageCount(props.musicalChairsPrompt.length - 4)
	}

	const onClickCancelEditPrompt = () => {
		setEditPromptMode(false)
		handleExitAddStage()
	}

	const onClickAddStage = () => {
		const size = props.musicalChairsPrompt.length
		props.musicalChairsPrompt.push([`${size + 1}`, '', ''])
		setAddStageCount((current) => current + 1)
	}

	const onClickSaveEditPrompt = async () => {
		const lastEditedIdea = document.getElementsByTagName('textarea')
		if (lastEditedIdea && lastEditedIdea.length >= 1) {
			if (
				props.musicalChairsPrompt[businessComparisonIndex][1] !==
				lastEditedIdea[0].value
			) {
				props.musicalChairsPrompt[businessComparisonIndex][1] =
					lastEditedIdea[0].value
				props.businessComparisonsHasChanged[businessComparisonIndex] =
					true
			}
			setEditPromptMode(false)
			handleExitAddStage()
			await props.reloadIdeas()
			if (showAlert) {
				showAlert({
					objectType: 'editPrompt',
					severity: ToastSeverity.SUCCESS,
				})
			}
		}
	}

	return (
		<>
			<Section2Title />
			<Grid container>
				<Grid item sx={styles.sideBar} />
				<Grid sx={styles.mainContainer}>
					<Grid
						item
						xs
						container
						direction="column"
						sx={styles.contentContainer}
					>
						<Grid item>
							<Typography
								data-testid={`${TEST_ID}__inspiration-text`}
							>
								Consider how you could achieve the innovation
								topic process with the altered process steps
								below.
							</Typography>
						</Grid>
						<Grid sx={styles.radioButtonSectionContainer} item>
							<RadioGroup
								aria-label="stage"
								name="stage"
								value={stageValue}
								onChange={handleStageChange}
								row
							>
								<FormControlLabel
									value="eliminate"
									control={
										<Radio
											sx={styles.radioButtonSelection}
										/>
									}
									label={
										<Typography variant="body2">
											ELIMINATE STAGE
										</Typography>
									}
								/>
								<FormControlLabel
									value="swap"
									control={
										<Radio
											sx={styles.radioButtonSelection}
										/>
									}
									label={
										<Typography variant="body2">
											SWAP STAGE
										</Typography>
									}
								/>
							</RadioGroup>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={refreshIcon}
								text="REFRESH"
								data-testid={`${TEST_ID}__refresh-button`}
								onClickAction={onClickRefresh}
							/>
						</Grid>
						<Grid item>
							<Grid
								container
								direction="row"
								id="business-comparison-ideas"
								data-testid="business-comparison-ideas"
								sx={styles.cardContainer}
							>
								{props.musicalChairsPrompt.map(
									(businessComparison, i) => (
										<>
											<Grid
												container
												direction="row"
												key={
													editPromptMode &&
													businessComparisonIndex ===
														i
														? `edit-business-comparison-${i}`
														: `business-comparison-${i}`
												}
												sx={styles.numberedCard}
											>
												<Grid
													flexGrow={1}
													onClick={() => {
														if (props.loading)
															return
														onClickBusinessComparison(
															i
														)
													}}
													sx={{
														...styles.cardHeader,
														cursor: props.loading
															? 'default'
															: 'pointer',
													}}
												>
													<Grid
														sx={styles.fullHeight}
													>
														{props.loading ? (
															<Grid textAlign="center">
																<CircularProgress
																	data-testid={`${TEST_ID}__comparison-spinner-${i}`}
																	color="secondary"
																	sx={
																		commonStyles.circularProgress
																	}
																/>
															</Grid>
														) : (
															<Grid
																container
																alignItems={
																	'center'
																}
																data-testid={`${TEST_ID}__business-comparison-${i}`}
																sx={
																	styles.fullHeight
																}
															>
																<Grid
																	sx={
																		styles.stepNumber
																	}
																>
																	<Typography
																		sx={{
																			...styles.stepNumberFont(
																				businessComparisonIndex ===
																					i &&
																					stageValue ===
																						'eliminate'
																			),
																		}}
																	>
																		{
																			businessComparison[0]
																		}
																	</Typography>
																</Grid>
																<Grid
																	sx={
																		styles.numberedCardText
																	}
																>
																	<Typography
																		sx={{
																			...styles.numberedCardFont(
																				businessComparisonIndex ===
																					i &&
																					stageValue ===
																						'eliminate'
																			),
																		}}
																		data-testid={`${TEST_ID}__ideation-prompt-${i}`}
																	>
																		{editPromptMode &&
																		businessComparisonIndex ===
																			i ? (
																			<TextField
																				style={
																					styles.editTextContainer
																				}
																				data-testid={`${TEST_ID}__edit-business-comparison-${i}`}
																				defaultValue={
																					props
																						.musicalChairsPrompt[
																						i
																					][1]
																				}
																				multiline
																				fullWidth
																				inputProps={{
																					style: styles.editPromptText,
																				}}
																				inputRef={(
																					input
																				) =>
																					input &&
																					input.focus()
																				}
																			/>
																		) : (
																			businessComparison[1]
																		)}
																	</Typography>
																</Grid>
																{i > 3 && (
																	<Typography
																		sx={
																			styles.additionalStageNumLabel
																		}
																		data-testid={`${TEST_ID}__add-stage-step`}
																	>
																		{parseInt(
																			businessComparison[0]
																		) -
																			4}{' '}
																		of{' '}
																		{
																			addStageCount
																		}
																	</Typography>
																)}
															</Grid>
														)}
													</Grid>
												</Grid>
												<Grid
													sx={
														i <
															props
																.musicalChairsPrompt
																.length -
																1 ||
														editPromptMode
															? styles.dividerContainerVisible
															: styles.dividerContainerHidden
													}
												>
													<Divider
														orientation="horizontal"
														sx={styles.boxDivider}
													/>
												</Grid>
											</Grid>
											{i ===
												props.musicalChairsPrompt
													.length -
													1 &&
												editPromptMode &&
												addStageCount < 2 && (
													<Button
														color={'secondary'}
														variant="outlined"
														data-testid={`${TEST_ID}__add-stage-button`}
														size="medium"
														onClick={() =>
															onClickAddStage()
														}
													>
														<AddIcon />
														<Typography>
															ADD STAGE
														</Typography>
													</Button>
												)}
										</>
									)
								)}
							</Grid>
						</Grid>
						<Grid item>
							{editPromptMode ? (
								<Grid sx={styles.editButtonsContainer}>
									<Button
										color="secondary"
										variant="contained"
										data-testid={`${TEST_ID}__edit-save-button`}
										onClick={async () => {
											setIsGenAiLoading(true)
											await onClickSaveEditPrompt()
											setIsGenAiLoading(false)
										}}
									>
										<Typography>SAVE CHANGES</Typography>
									</Button>
									<Button
										variant="text"
										color="secondary"
										data-testid={`${TEST_ID}__edit-cancel-button`}
										onClick={onClickCancelEditPrompt}
									>
										<Typography>CANCEL</Typography>
									</Button>
								</Grid>
							) : (
								<Grid sx={styles.editPromptContainer}>
									<ButtonWithIconComponent
										sx={{ padding: 0 }}
										disabled={props.loading}
										iconSvg={editIcon}
										text="EDIT"
										data-testid={`${TEST_ID}-edit-prompt`}
										onClickAction={onClickEditPrompt}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item>
							<Typography
								data-testid={`${TEST_ID}__apply-prompt-text`}
								sx={styles.ideasDropdown}
							>
								{brainstormText.global.ideasDropdown}
							</Typography>
							<GeneratedExampleDropdown
								gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
								headerText={''}
								isLoading={isGenAiLoading}
								customResponse={buildResponseItem(
									props.currentResponses[
										businessComparisonIndex
									]
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default Section2
