import { createTheme } from '@mui/system'

import { ChipColors } from './colors/chips'

interface BackgroundColors {
	hover: string
	paper: string
}

interface DropDownColors {
	border: string
	hover: string
	main: string
	textColor: string
}

interface PrimaryColors {
	dark: string
	disabled: string
	hover: string
	light: string
	main: string
}

interface SecondaryColors {
	hover: string
	light: string
	main: string
}

interface ErrorColors {
	disabled: string
	hover: string
	light: string
	main: string
}

interface TextColors {
	disabled: string
	hint: string
	input: string
	primary: string
	secondary: string
}

interface ActionColors {
	active: string
	disabled: string
	focus: string
	hover: string
	selected: string
}
interface errorDisabledColor {
	main: string
}
interface disabledColor {
	main: string
}

interface AsteriskColor {
	main: string
}
interface PaletteType {
	action: ActionColors
	asterisk: AsteriskColor
	background: BackgroundColors
	chipColor: ChipColors
	disabledColor: disabledColor
	dropDownColor: DropDownColors
	error: ErrorColors
	errorDisabledColor: errorDisabledColor
	primary: PrimaryColors
	secondary: SecondaryColors
	text: TextColors
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		customTitle: React.CSSProperties
	}

	interface TypographyVariantsOptions {
		customTitle?: React.CSSProperties
	}
}

// Add the new variant to Typography's props
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		blackText: true
		customTitle: true
	}
}

// Create theme with custom typography variant
export const typographyTheme = createTheme({
	typography: {
		customTitle: {
			fontSize: '1.4rem',
			marginTop: '2rem',
			marginBottom: '2rem',
		},
	},
})

export const palette: PaletteType = {
	background: {
		hover: '#F2F4F6',
		paper: '#F9F9F9',
	},
	dropDownColor: {
		border: '#45657B',
		hover: '#DBE0E4',
		main: '#45657B',
		textColor: '#FFFFFF',
	},
	chipColor: {
		battleships: {
			main: '#FFE0B2',
		},
		dressup: {
			main: '#B3E5FC',
		},
		teeterTotter: {
			main: '#FFA1BD',
		},
		musicalChairs: {
			main: '#E2F5F8',
		},
		oppositeDay: {
			main: '#DCEFFE',
		},
		buildingBlocks: {
			main: '#FEF1F3',
		},
	},
	primary: {
		disabled: '#DDAA97',
		light: '#F2F4F6',
		hover: '#B2350E',
		main: '#CF4F27',
		dark: '#274A62',
	},
	secondary: {
		hover: '#274A62',
		main: '#45657B',
		light: '#6A6A6A',
	},
	error: {
		disabled: '#FCECE9',
		light: '#E38E81',
		hover: '#AB3227',
		main: '#C53B2E',
	},
	text: {
		input: '#969696',
		primary: '#0C4152',
		secondary: '#626262',
		disabled: '#93A2AF',
		hint: '#93A2AF',
	},
	action: {
		selected: '#45657B',
		hover: '#274A62',
		disabled: '#93A2AF',
		focus: '#CF4F27',
		active: '#B2350E',
	},
	asterisk: { main: '#D32F2F' },
	disabledColor: { main: '#93A2AF' },
	errorDisabledColor: { main: '#FCECE9' },
}
