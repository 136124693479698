import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Container } from '@mui/system'
import logger from 'utils/logger'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { MusicalChairsModel } from 'models/MusicalChairsModel'
import { Game } from 'models/gameModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import {
	postMusicalChairsPrompt,
	postMusicalChairsResponse,
} from 'services/completions.service'
import { postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'
import { IdeationRecommendationModal } from 'components/IdeationRecommendationModal/IdeationRecommendationModal'

import { alertTitleTextMap, defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { SUBMITTED_IDEAS } from 'assets/routes'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'
import { BusinessTopicType } from 'enums/TopicTypeEnum'

import Hero from '../../components/Hero/Hero'
import { styles } from './MusicalChairs.styles'
import Section1 from './components/Section1/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'

export const TEST_ID = 'musical-chairs'

const MusicalChairs = ({ game }: { game?: Game }) => {
	const [ideaPrompt, setIdeaPrompt] = useState(false)
	const [submitIdeas, setSubmitIdeas] = useState(false)
	const [refreshClicksSinceIdeation, setRefreshClicksSinceIdeation] =
		useState(0)

	useEffect(() => {
		if (game) {
			setInnovationCompany(game.innovationCompany)
			setInnovationTopic(game.innovationTopic)
			setCompanyProblem(game.companyProblem)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const {
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		promptConversation,
		setPromptConversation,
		authorIdeation,
		setAuthorIdeation,
		innovationTopic,
		setInnovationTopic,
		setPromptResponse,
		businessComparisons,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		promptToJSON,
		responseToJSON,
		resetSoft,
		submitIdeasDisabled,
		gameToJSON,
		reset,
		setGameId,
		generateScenarioDisabled,
		businessComparisonsHasChanged,
		termsAgreed,
		setTermsAgreed,
		musicalChairsPrompt,
	} = MusicalChairsModel()

	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		setIdeaPrompt(authorIdeation.length >= 8)
	}, [authorIdeation])

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (ideaPrompt) {
				event.preventDefault()
			}
		}

		if (ideaPrompt) {
			window.addEventListener('beforeunload', handleBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [ideaPrompt])

	const { setIdeaStreakCount } = useIdeaStreakContext()
	const { showAlert } = useToastContext()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)
	const [, setIsAiLoading] = useState([false, false, false, false])
	const [aiExampleVisible, setAiExampleVisible] = useState<
		Record<number, boolean>
	>({})

	const userMessage = {
		content: '',
		role: 'user',
	}

	const updateAIResponse = (content: string) => {
		return {
			content,
			role: 'assistant',
		}
	}

	const resetAllField = () => {
		setInnovationCompany('')
		setInnovationTopic('')
		setCompanyProblem('')
	}

	const reloadIdeas = async () => {
		for (let i = 0; i <= musicalChairsPrompt.length - 1; i++) {
			if (businessComparisonsHasChanged[i]) {
				await handleAiResponseClick(i, {}, false)
			}
		}
	}

	const refreshPrompt = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			authorIdeation.length > 0
		) {
			return ShowWarningModal(
				{
					state: 'blocked',
					location: {
						pathname: '',
						search: '',
						hash: '',
						state: null,
						key: 'kxdgy6c2',
					},
					reset: () => logger.debug('reset'),
					proceed: () => createPrompt(),
				},
				showModal
			)
		}

		// Only generate new prompts if user has ideated since last 3 refreshes
		if (refreshClicksSinceIdeation < 2) {
			createPrompt()
		}

		setRefreshClicksSinceIdeation(refreshClicksSinceIdeation + 1)
	}

	const createPrompt = async () => {
		try {
			setIsLoading(true)
			resetSoft()

			// setup the conversation
			const prompt = promptToJSON()
			prompt.conversation = [...promptConversation, userMessage]
			setStep(GAME_STEP.Step2)
			window.scrollTo(0, 0)
			const response = await postMusicalChairsPrompt(prompt)

			// setup history
			if (response.status === 200 && response.data) {
				if (typeof response.data !== 'object') {
					throw Error(
						`invalid response type. expected: object, actual: ${typeof response.data}`
					)
				}

				const msg = JSON.stringify(response.data)
				// add response to state
				setPromptConversation((prevMessages) => [
					...prevMessages,
					userMessage,
					updateAIResponse(msg),
				])

				// setup the page correctly
				setPromptResponse(response)
			}
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert({
					title: defaultErrorMessage.title,
					bodyText: defaultErrorMessage.bodyText,
					severity: ToastSeverity.ERROR,
				})
			}
		} finally {
			setIsLoading(false)
		}
	}

	const viewSubmittedIdeas = () => {
		window.scrollTo(0, 0)
		navigate(SUBMITTED_IDEAS)
	}

	const handleAiResponseClick = async (
		i: number,
		additionalProps = {},
		isUserInitiated = true
	) => {
		if (isUserInitiated && !aiExampleVisible[i]) {
			setAiExampleVisible({ ...aiExampleVisible, [i]: true })
			return
		}
		// set is loading
		setIsAiLoading((oldIsAiLoading) => {
			// we need the item '_cur' here even though it's not used.
			return oldIsAiLoading.map((_cur, idx) => {
				if (i === idx) {
					return true
				}
				return false
			})
		})

		try {
			// setup the conversation
			const prompt = responseToJSON(i)
			prompt.conversation = [...responseConversation[i], userMessage]

			const response = await postMusicalChairsResponse({
				...prompt,
				...additionalProps,
			})

			if (response.status === 200 && response.data) {
				// Use the new response format
				const parsedResponse = response.data.response

				// Add the response to conversation state
				const msg = JSON.stringify(parsedResponse)

				// add response to state
				const entireResponse = [
					...responseConversation[i],
					userMessage,
					updateAIResponse(msg),
				]

				setResponseConversation((oldResponseConversation) => {
					return oldResponseConversation.map((cur, idx) => {
						if (i === idx) {
							return entireResponse
						}
						return cur
					})
				})

				setCurrentResponses((oldCurrentResponses) => {
					return oldCurrentResponses.map((cur, idx) => {
						if (i === idx) {
							return JSON.stringify(parsedResponse)
						}
						return cur
					})
				})
			} else {
				throw Error(`response received ${response.data}`)
			}
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert({
					title: alertTitleTextMap.submitIdeaError.title,
					bodyText: alertTitleTextMap.submitIdeaError.text,
					severity: ToastSeverity.ERROR,
				})
			}

			// Fallback response for demo/testing purposes
			const fallbackResponse = [
				[
					i + 1,
					'API Error: Could not retrieve response. Please try again later.',
					'',
				],
			]

			// Set fallback data to maintain UI continuity
			setCurrentResponses((oldCurrentResponses) => {
				return oldCurrentResponses.map((cur, idx) => {
					if (i === idx) {
						return JSON.stringify(fallbackResponse)
					}
					return cur
				})
			})
		} finally {
			setIsAiLoading((oldIsAiLoading) => {
				return oldIsAiLoading.map(() => {
					return false
				})
			})
		}
	}

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(gameToJSON())

			if (showAlert) {
				showAlert({
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
			setIdeaStreakCount((prevState) => prevState + 1)
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert({
					title: alertTitleTextMap.submitIdeaError.title,
					bodyText: alertTitleTextMap.submitIdeaError.text,
					severity: ToastSeverity.ERROR,
				})
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)

			// need to reset the model
			reset()
			window.scrollTo({
				top: 0,
			})

			// Load new prompt
			createPrompt()
		}
	}

	const onIdeationRecommendationBackClicked = () => {
		setStep(GAME_STEP.Step1)
		onIdeationRecommendationModalClosed()
	}

	const onIdeationRecommendationModalClosed = () => {
		setRefreshClicksSinceIdeation(0)
	}

	useEffect(() => {
		setRefreshClicksSinceIdeation(0)
	}, [authorIdeation])

	return (
		<Box>
			<IdeationRecommendationModal
				refreshClicksSinceIdeation={refreshClicksSinceIdeation}
				onBack={onIdeationRecommendationBackClicked}
				onClose={onIdeationRecommendationModalClosed}
			/>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
				innovationTopic={innovationTopic as BusinessTopicType}
				innovationCompany={innovationCompany}
				companyProblem={companyProblem}
				isLoading={isLoading}
				setCompanyProblem={setCompanyProblem}
				setInnovationCompany={setInnovationCompany}
				setInnovationTopic={setInnovationTopic}
				createPrompt={createPrompt}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth="md"
				sx={styles.container}
			>
				<Box paddingBottom={'2rem'}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === GAME_STEP.Step1,
								onClick:
									step !== GAME_STEP.Step1
										? () => setStep(GAME_STEP.Step1)
										: undefined,
							},
							{
								label: '2. FACILITATION TECHNIQUE & 3. IDEATION',
								active: step !== GAME_STEP.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<Section1
						data-testid="musical-chairs-section1__container"
						isLoading={isLoading}
						innovationCompany={innovationCompany}
						setInnovationCompany={setInnovationCompany}
						innovationTopic={innovationTopic as BusinessTopicType}
						setInnovationTopic={setInnovationTopic}
						companyProblem={companyProblem}
						setCompanyProblem={setCompanyProblem}
						generateScenarioDisabled={generateScenarioDisabled}
						resetAllField={resetAllField}
						createPrompt={createPrompt}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
					/>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<Section2
							loading={isLoading}
							businessComparisons={businessComparisons}
							businessComparisonsHasChanged={
								businessComparisonsHasChanged
							}
							currentResponses={currentResponses}
							createPrompt={refreshPrompt}
							reloadIdeas={reloadIdeas}
							musicalChairsPrompt={musicalChairsPrompt}
						/>
						<Section3
							submitIdeasDisabled={submitIdeasDisabled}
							loading={isSubmitButtonLoading}
							submitIdeas={handleSubmitIdeas}
							authorIdeation={authorIdeation}
							setAuthorIdeation={setAuthorIdeation}
							viewSubmittedIdeas={viewSubmittedIdeas}
							cautionText={
								game?.gameTitle || game?.endTime
									? brainstormText.global.alternateWarningText
									: brainstormText.global.warningText
							}
							termsAgreed={termsAgreed}
							setTermsAgreed={setTermsAgreed}
							gameId={game?.gameId}
						/>
					</>
				)}
			</Container>
		</Box>
	)
}

export default MusicalChairs
