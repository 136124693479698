export const styles = {
	header: {
		opacity: 0.87,
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #12BBF182 51%, #12BBF182 100%)',
	},
	carouselWrapper: {
		backgroundColor: 'rgba(245, 245, 245, 1)',
		padding: '15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .button-wrapper': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 50,
			width: 'max-content',

			'@media (max-width: 600px)': {
				border: 'none',
				flexWrap: 'wrap',
			},

			'& button': {
				fontSize: '14px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.87)',
				padding: '10px',
				textTransform: 'uppercase',
				'@media (max-width: 1200px)': {
					fontSize: '12px',
				},
				'@media (max-width: 600px)': {
					borderRadius: 50,
					border: '1px solid rgba(0, 0, 0, 0.23)',
					marginTop: '5px',
					marginBottom: '5px',
				},
				':hover': {
					bgcolor: 'rgba(231, 248, 255, 0.4)',
				},
				backgroundColor: 'white',

				'&.selected': {
					backgroundColor: 'rgba(231, 248, 255, 1)',
				},

				'&:first-child': {
					borderTopLeftRadius: {
						sm: '18px',
						md: '18px',
					},
					borderBottomLeftRadius: {
						sm: '18px',
						md: '18px',
					},
				},

				'&:last-child': {
					borderTopRightRadius: {
						sm: '18px',
						md: '18px',
					},
					borderBottomRightRadius: {
						sm: '18px',
						md: '18px',
					},
				},
			},
		},
	},
}
