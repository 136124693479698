export const styles = {
	background: {
		alignItems: 'start',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		position: 'absolute',
		width: '100%',
	},
	borderContainer: {
		opacity: '35%',
		width: '100%',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		position: 'relative',
		zIndex: 1,
		background: 'linear-gradient(180deg, #F1F5FD 0%, #D0DFF4 100%)',
	},
	content: {
		maxWidth: '120rem',
		justifyContent: 'center',
	},
	gameCardContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	gameCardContent: {
		maxWidth: '1260px',
	},
	gameCardContainerBaseGridItem: {
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
	},
	gameCardContainerCardGridItem: {
		display: 'flex',
		marginBottom: {
			xs: '28px',
			sm: '85px',
		},
		'&:nth-last-child(3)': {
			marginBottom: {
				xs: '28px',
				sm: '85px',
				lg: 0,
			},
		},
		'&:nth-last-child(2)': {
			marginBottom: {
				xs: 0,
				sm: 0,
			},
		},
	},
	gameCardTopDivider: {
		borderWidth: '1px',
		width: '90%',
		borderColor: '#45657B',
		marginTop: {
			lg: '63px',
			sm: '79px',
			xs: '40px',
		},
		marginBottom: {
			lg: '80px',
			sm: '47px',
			xs: '29px',
		},
	},
	gameCardBottomDivider: {
		borderWidth: '1px',
		width: '100%',
		borderColor: '#45657B',
		marginTop: {
			lg: '111px',
			sm: '74px',
			xs: '62px',
		},
	},
	divider: {
		'&.MuiDivider-root': {
			'&::after': {
				borderTop: 'thin solid #45657B',
			},
			'&::before': {
				borderTop: 'thin solid #45657B',
			},
		},

		borderWidth: { xs: '0px', lg: '10px' },
		margin: 'auto',
		marginTop: '5rem',
		paddingLeft: { xs: '0', lg: '2rem' },
		width: '100%',
	},
	dividerText: {
		color: 'secondary.main',
		fontWeight: 'bold',
		paddingLeft: { xs: '10px', lg: '2rem' },
		paddingRight: '2rem',
	},
	gamesContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '3.33rem',
	},
	header: {
		color: 'text.primary',
		fontSize: { lg: '5.5rem', sm: '5.5rem', xs: '3.4rem' },
		fontStyle: 'normal',
		fontWeight: '300',
		margin: '6rem 0 0 0',
		textAlign: 'center',
		width: '100%',
	},
	homePage: {
		alignContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		marginBottom: { lg: '9.9rem', sm: '6.8rem', xs: '6.1rem' },
	},
	hr: {
		backgroundColor: 'primary.main',
		minHeight: '.1rem',
		width: '100%',
	},
	leftWaveBorder: {
		backgroundAttachment: 'fixed',
		backgroundPosition: 'top left',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'auto 100%',
		bottom: 0,
		height: '100%',
		left: 0,
		position: 'fixed',
		top: 0,
		width: '50%',
	},
	lowerContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: {
			lg: '108px',
			sm: '66px',
			xs: '57px',
		},
		marginBottom: {
			lg: '108px',
			sm: '66px',
			xs: '57px',
		},
	},

	rightWaveBorder: {
		backgroundAttachment: 'fixed',
		backgroundPosition: 'top right',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'auto 100%',
		bottom: 0,
		height: '100%',
		position: 'fixed',
		right: 0,
		top: 0,
		width: '50%',
	},
	secondDivider: {
		marginBottom: '10rem',
		marginTop: '-8rem',
	},
	spacer: {
		flexGrow: '0',
		width: '10rem',
	},
	subheader: {
		color: '#000000DE',
		fontSize: { lg: '3.3rem', sm: '2rem' },
		fontWeight: '300',
		margin: '1rem',
		marginTop: '4rem',
		textAlign: 'center',
		width: '100%',
	},
	filtersHeader: {
		display: 'block',
		textAlign: 'center',
		color: '#000000DE',
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
		fontSize: {
			lg: '3.4rem',
			sm: '2.4rem',
			xs: '1.8rem',
		},
		marginBottom: {
			lg: '5.4rem',
			sm: '4rem',
			xs: '2.3rem',
		},
	},
	filerLabel: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		fontSize: {
			lg: '20px',
			sm: '18px',
			xs: '16px',
		},
		color: '#000000DE',
		marginLeft: '2rem',
		marginRight: '2rem',
	},
	outerFiltersContainer: {
		justifyContent: 'center',
		flexDirection: 'column',
	},
	innerFiltersContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		whiteSpace: 'nowrap',
		marginBottom: '5rem',
	},
	chip: {
		margin: '1.2rem',
		fontSize: '2rem',
		color: '#000000DE',
		minHeight: '5.4rem',
		cursor: 'pointer',
		borderRadius: '10rem',
		'&&:hover, &&:focus': {
			color: 'white',
			background: '#0C4152',
		},
		background: 'linear-gradient(90deg, #9DCDF2 0%, #BDDEF6 100%)',
		borderColor: '#00000042',
		padding: '8px 16px 8px 16px',
	},
}
