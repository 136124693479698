export const styles = {
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '2rem',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
			gap: '2rem',
		},
	},
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	chip: {
		paddingX: '0.5rem',
		color: 'black',
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #F84BB2 0%, #FC6767 100%)',
	},
	header: {
		marginY: '2rem',
	},
}
