/* istanbul ignore file */
// NOTE: This file was ignored from coverage as it's a wrapper to test react-router functionality
import { Blocker, useBlocker } from 'react-router-dom'

import { MODAL_TYPES, ModalType } from '../contexts/ModalProvider'
import { EMPTY_ARRAY_CHECK } from './ideationRegex'

export const UseBlocker = (
	fields: (string | null | string[])[],
	submitIdeas: boolean = false
) => {
	return useBlocker(({ currentLocation, nextLocation }) => {
		const hasInput = (value: string | null | string[]) => {
			if (typeof value === 'string') {
				// Check if the string is meaningful and not just an empty tag like <p></p>
				const strippedValue = value
					.replace(EMPTY_ARRAY_CHECK, '')
					.trim()
				return !!strippedValue
			}
			// For arrays, check if they have a length and are not empty strings or null
			if (Array.isArray(value)) {
				return value.some(
					(v) => v && v.replace(EMPTY_ARRAY_CHECK, '').trim()
				)
			}
			return !!value
		}

		if (submitIdeas) {
			return false
		} else {
			return (
				fields.some(hasInput) &&
				currentLocation.pathname !== nextLocation.pathname
			)
		}
	})
}

type WarningModalProps = {
	onCancel: () => void
	onProceed: () => void
}

type ShowModalFunction = (
	modalType: ModalType,
	modalProps?: WarningModalProps
) => void

export const ShowWarningModal = (
	blocker: Blocker,
	showModal: ShowModalFunction
) => {
	if (blocker?.state === 'blocked') {
		return showModal(MODAL_TYPES.WARNING_MODAL, {
			onProceed: blocker.proceed,
			onCancel: blocker.reset,
		})
	}
}
