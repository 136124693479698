import { GameType } from 'enums/GameTypeEnum'

export const styles = (
	heroData: GameType
): { [key: string]: React.CSSProperties } => {
	const bgGradient: string = heroData.backgroundGradient
	return {
		mainGrid: {
			paddingTop: '1.5rem',
			paddingBottom: '3rem',
			background: bgGradient,
		},
		heroImg: {
			objectFit: 'contain',
			alignSelf: 'flex-start',
			minHeight: '30rem',
			maxHeight: '45rem',
			height: '100%',
			width: '100%',
		},
		innovationTopicBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		innovationTopicLabel: {
			fontSize: '12px',
			letterSpacing: '1px',
			color: 'black',
		},
		actionButtonBox: {
			display: 'flex',
			marginBottom: '4px',
		},
		shareGameBox: {
			display: 'flex',
			alignItems: 'center',
			padding: '7px 8px 7px 0px',
			gap: '8px',
			margin: '0 12px',
		},
		iconButton: {
			padding: '0',
		},
		icon: {
			fontSize: '18px',
		},
		shareGameLabel: {
			fontSize: '13px',
			fontWeight: '500',
			lineHeight: '0',
			cursor: 'pointer',
		},
		editBox: {
			display: 'flex',
			padding: '4px 0px',
			alignItems: 'center',
			gap: '8px',
		},
		editLabel: {
			fontWeight: '500',
			fontSize: '13px',
			lineHeight: '0',
			cursor: 'pointer',
		},
	}
}
