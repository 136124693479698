export const styles = {
	carouselWrapper: {
		display: 'flex',
		flexDirection: {
			xs: 'column',
			sm: 'row',
		},
		alignItems: 'center',
		justifyContent: 'center',
		gap: '3rem',
		bgcolor: '#F5F5F5',
		padding: '2rem',
		'& .button-wrapper': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 50,
			width: 'max-content',

			'@media (max-width: 600px)': {
				border: 'none',
				flexWrap: 'wrap',
			},

			'& button': {
				fontSize: '14px',
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.87)',
				padding: '10px',
				textTransform: 'uppercase',
				'@media (max-width: 1200px)': {
					fontSize: '12px',
				},
				'@media (max-width: 600px)': {
					borderRadius: 50,
					border: '1px solid rgba(0, 0, 0, 0.23)',
					marginTop: '5px',
					marginBottom: '5px',
				},
				':hover': {
					bgcolor: 'rgba(231, 248, 255, 0.4)',
				},
				backgroundColor: 'white',

				'&.selected': {
					bgcolor: '#D6D4FF',
				},

				'&:first-child': {
					borderTopLeftRadius: {
						sm: '18px',
						md: '18px',
					},
					borderBottomLeftRadius: {
						sm: '18px',
						md: '18px',
					},
				},

				'&:last-child': {
					borderTopRightRadius: {
						sm: '18px',
						md: '18px',
					},
					borderBottomRightRadius: {
						sm: '18px',
						md: '18px',
					},
				},
			},
		},
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background:
			'linear-gradient(180deg, rgba(0, 37, 230, 0.9) -12.94%, rgba(39, 35, 249, 0.5) 100%)',
	},
	editPromptsMode: {
		'.MuiInputBase-input': {
			fontWeight: '500',
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: {
				xs: 16,
				sm: 20,
			},
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.MuiOutlinedInput-root': {
			borderRadius: '0',
			borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
			backgroundColor: '#FFFFFF',
		},
	},
	promptsText: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontWeight: 500,
		fontSize: {
			xs: 16,
			sm: 20,
		},
	},
}
