import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'

export function BuildingBlocksModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')

	const generateScenarioDisabled = () =>
		[innovationTopic, innovationCompany, companyProblem].some(
			(field) => !field.length
		)
	const gameTypeId = GAME_TYPE.BUILDING_BLOCKS.value
	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}
	return {
		authorIdeation,
		setAuthorIdeation,
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		innovationTopic,
		setInnovationTopic,
		setGameId,
		generateScenarioDisabled,
		gameToJSON,
	}
}
