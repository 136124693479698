import React, { forwardRef } from 'react'

import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'

export const TEST_ID = 'default-test-id-check-box'
export const CONTAINER_TEST_ID_SUFFIX = '-control-label'

export type Props = {
	'data-testid'?: string
	label: React.ReactNode
} & CheckboxProps

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	'data-testid'?: string
}

const BaseCheckBoxField = forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			required,
			disabled,
			color,
			'data-testid': dataTestId,
			checked,
			...props
		},
		ref
	) => {
		return (
			<FormControlLabel
				inputRef={ref}
				data-testid={`${dataTestId || TEST_ID}${CONTAINER_TEST_ID_SUFFIX}`}
				control={
					<Checkbox
						inputProps={
							{
								'data-testid': dataTestId || TEST_ID,
								disabled,
							} as CustomInputProps
						}
						{...props}
					/>
				}
				label={label}
				required={required}
				disabled={disabled}
				color={color}
				checked={checked}
			/>
		)
	}
)

BaseCheckBoxField.displayName = 'BaseCheckBoxField'

export default BaseCheckBoxField
