import React, { Dispatch, SetStateAction, useRef } from 'react'

import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { RichTextField } from 'components/Form'
import IdeaFlameMotivator, {
	useRichTextToIdeas,
} from 'components/IdeaFlameMotivator/IdeaFlameMotivator'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section3.styles'

export const TEST_ID = 'opposite-day-section3'

export type Props = {
	authorIdeation: string
	cautionText?: string
	gameId?: string
	loading: boolean
	setAuthorIdeation: (val: string) => void
	setTermsAgreed: Dispatch<SetStateAction<boolean>>
	submitIdeas: () => void
	submitIdeasDisabled: () => boolean
	termsAgreed: boolean
	viewSubmittedIdeas: () => void
}

const Section3 = (props: Props) => {
	const richTextFieldRef = useRef(null)
	const richTextToIdeas = useRichTextToIdeas()

	function handleCheckbox() {
		props.setTermsAgreed(!props.termsAgreed)
	}

	return (
		<>
			<Typography sx={styles.ideationTypography}>3. IDEATION</Typography>
			<Box sx={styles.mainBox}>
				<Box sx={styles.sideBar} />
				<Box sx={styles.typographyBox}>
					<Typography
						sx={styles.richTextFieldDescription}
						data-testid={`${TEST_ID}__first_typography`}
					>
						How can you borrow inspiration from this prompt and
						apply it to our innovation topic?
					</Typography>
					<Grid
						item
						sx={styles.richTextFieldGrid}
						data-testid={`${TEST_ID}__ideation`}
					>
						<RichTextField
							ref={richTextFieldRef}
							onChange={props.setAuthorIdeation}
							value={props.authorIdeation}
							placeholderText="Brainstorm your ideas here!"
							sx={styles.richTextField}
							iconColor="#45657B"
						/>
					</Grid>
					<Box>
						<IdeaFlameMotivator
							ideaCount={richTextToIdeas(props.authorIdeation)}
							variant="OPPOSITE_DAY"
						/>
					</Box>
					{props.gameId && (
						<Box sx={styles.gameIdBox}>
							<input
								type="checkbox"
								checked={props.termsAgreed}
								onChange={handleCheckbox}
								data-testid={`${TEST_ID}__term_agreement_checkbox`}
							/>
							<Typography
								data-testid={`${TEST_ID}__term_agreement_text`}
							>
								I understand my submitted ideas are only visible
								to me and the game creator.
							</Typography>
						</Box>
					)}
					<Box sx={styles.submitButtonBox}>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__submit-idea-button`}
							onClick={props.submitIdeas}
							disabled={
								props.submitIdeasDisabled() || props.loading
							}
						>
							{props.loading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>SUBMIT IDEAS</Typography>
							)}
						</Button>
						<Button
							disabled={props.loading}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__view-submit-ideas-button`}
							onClick={props.viewSubmittedIdeas}
						>
							{props.loading ? (
								<CircularProgress
									color="secondary"
									sx={commonStyles.circularProgress}
								/>
							) : (
								<Typography>
									VIEW ALL SUBMITTED IDEAS
								</Typography>
							)}
						</Button>
					</Box>
					<Typography sx={styles.cautionTypography}>
						{props.cautionText}
					</Typography>
				</Box>
			</Box>
		</>
	)
}
export default Section3
