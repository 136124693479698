export const styles = {
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	chip: {
		paddingX: '0.5rem',
		color: 'black',
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(0deg, #ff8a9f 60%, #f3123f 90%)',
		marginRight: '2rem',
	},
	typography: {
		marginY: '2rem',
		fontSize: '1.4rem',
	},
	question1Box: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '3rem',
		flexGrow: '1',
	},
	enterCompany: {
		'& .MuiInputBase-input': {
			boxSizing: 'content-box',
			height: '100%',
		},
	},
	summarizeBusinessProblem: {
		opacity: '87%',
	},
	enterProblem: {
		'& .MuiInputBase-input': {
			boxSizing: 'content-box',
			height: '100%',
		},
	},
	buttonFlex: {
		flexDirection: {
			sm: 'row',
		},
	},
}
