import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Button, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { BaseCheckBoxField, BaseTextField } from 'components/Form'

import { typography } from 'theme/typography'

import { commonStyles } from 'styles/common.styles'

import { styles } from './Section1.styles'

export const TEST_ID = 'simon-says-section1'

export type Props = {
	createPrompt: () => void
	customerExperienceDisclaimerChecked: boolean
	customerJourney: string
	editable: boolean
	generateScenarioDisabled: () => boolean
	innovationCompany: string
	isLoading: boolean
	resetAllField: () => void
	setCustomerExperienceDisclaimerChecked: React.Dispatch<
		React.SetStateAction<boolean>
	>
	setCustomerJourney: (val: string) => void
	setInnovationCompany: (val: string) => void
}

const Section1 = ({
	innovationCompany,
	setInnovationCompany,
	customerJourney,
	setCustomerJourney,
	generateScenarioDisabled,
	resetAllField,
	isLoading,
	createPrompt,
	editable,
	setCustomerExperienceDisclaimerChecked,
	customerExperienceDisclaimerChecked,
}: Props) => {
	return (
		<>
			<Typography
				variant={'overline'}
				sx={{ ...commonStyles.header, ...styles.header }}
			>
				1. INNOVATION TOPIC
			</Typography>
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
						<Typography
							sx={{ opacity: '87%', color: 'black' }}
							data-testid={`${TEST_ID}__company-brainstorm-prompt`}
						>
							What company are you brainstorming about today?{' '}
							<Box component="span" sx={typography.asterisk}>
								*
							</Box>
						</Typography>
					</Box>

					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
							required: false,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-name-input`}
						label="Enter company name here."
						value={innovationCompany}
						onChangeValue={(val) => setInnovationCompany(val)}
						required
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<div style={styles.checkbox}>
						<BaseCheckBoxField
							label={
								<Typography sx={styles.checkboxText}>
									NOTE: This game is focused on{' '}
									<b>customer experience.</b> Check the box to
									confirm you understand.
								</Typography>
							}
							checked={customerExperienceDisclaimerChecked}
							onChange={(e) =>
								setCustomerExperienceDisclaimerChecked(
									e.target.checked
								)
							}
							disabled={!editable}
							color="primary"
							data-testid={`${TEST_ID}__checkbox`}
						/>
					</div>

					<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
						<Typography
							sx={{ opacity: '87%', color: 'black' }}
							data-testid={`${TEST_ID}__summarize-prompt`}
						>
							Summarize the customer experience problem you want
							to ideate on (in 5-10 words).
						</Typography>
						<Typography
							sx={{
								color: 'rgba(211, 47, 47, 1)',
								marginLeft: '8px',
							}}
						>
							*
						</Typography>
					</Box>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
							required: false,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__customer-journey-input`}
						label="Summarize in 5-10 words"
						onChangeValue={(val) => setCustomerJourney(val)}
						value={customerJourney}
						size="medium"
						required
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Box
						display="flex"
						sx={{
							flexDirection: {
								xs: 'column',
								sm: 'row',
							},
						}}
						gap="2rem"
					>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generateScenarioDisabled() || isLoading}
							onClick={createPrompt}
						>
							START IDEATING
						</Button>
						<Button
							disabled={!editable}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAllField}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section1
