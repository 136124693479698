import BattleShipsBannerImage from 'assets/images/BattleShips/Hero.svg'
import BuildingBlocksBannerImage from 'assets/images/BuildingBlocks/Hero.svg'
import DressUpBannerImage from 'assets/images/DressUp/Hero.svg'
import BattleShipsIcon from 'assets/images/GameIcons/BattleShips.svg'
import DressUpIcon from 'assets/images/GameIcons/DressUp.svg'
import LeapFrogIcon from 'assets/images/GameIcons/LeapFrog.svg'
import MusicalChairsIcon from 'assets/images/GameIcons/MusicalChairs.svg'
import RoundRobinIcon from 'assets/images/GameIcons/RoundRobin.svg'
import SlotMachineIcon from 'assets/images/GameIcons/SlotMachine.svg'
import LeapFrogBannerImage from 'assets/images/LeapFrog/Hero.svg'
import MusicalChairsBannerImage from 'assets/images/MusicalChairs/Hero.svg'
import OppositeDayBannerImage from 'assets/images/OppositeDay/Hero.svg'
import RoundRobinBannerImage from 'assets/images/RoundRobin/Hero.svg'
import SimonSaysBannerImage from 'assets/images/SimonSays/Hero.svg'
import TeeterTotterBannerImage from 'assets/images/TeeterTotter/Hero.svg'
import {
	BATTLE_SHIPS,
	DRESS_UP,
	LEAP_FROG,
	MUSICAL_CHAIRS, // DRESS_UP,
	// MUSICAL_CHAIRS,
	ROUND_ROBIN, // SLOT_MACHINE,
	SIMON_SAYS,
	TEETER_TOTTER,
} from 'assets/routes'

export interface GameType {
	backgroundGradient: string
	description: string
	filters: string[]
	gameLink: string
	gameTitle?: string
	icon: string
	image: string
	label: string
	modalText?: {
		chipsQuestion: string
		inputPlaceholder: string
		inputQuestion: string
	}
	shortDesc?: string
	subtitle?: string
	subtitle2?: string
	useCase: string
	useCaseBackgroundColor: string
	value: number
}

export const GAME_TOPIC_FILTERS = {
	MARKETING: 'MARKETING',
	SALES: 'SALES',
	CUSTOMER_EXPERIENCE: 'CUSTOMER EXPERIENCE',
	OPERATIONS: 'OPERATIONS',
}

export const GAME_TYPE: Record<string, GameType> = {
	DRESS_UP: {
		value: 4,
		label: 'Dress Up',
		icon: DressUpIcon as unknown as string,
		useCase: 'Cross-Industry Comparison',
		filters: [
			GAME_TOPIC_FILTERS.MARKETING,
			GAME_TOPIC_FILTERS.SALES,
			GAME_TOPIC_FILTERS.CUSTOMER_EXPERIENCE,
		],
		description:
			'Draw inspiration from other industries’ strategies to spark ideas for your own.',
		gameLink: DRESS_UP,
		image: DressUpBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(114, 164, 239, 0.4) 0%, rgba(18, 187, 241, 0.204) 100%);',
		useCaseBackgroundColor: '#2B5084',
		shortDesc: 'for cross-industry inspiration',
		subtitle:
			'Transforming your business can be like a game of dress-up for industries.',
		subtitle2:
			'Just as mixing and matching outfits create unique styles, comparing your business across industries lets you accessorize with innovative ideas you may have never considered before.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
	},
	SIMON_SAYS: {
		value: 1,
		label: 'Simon Says',
		icon: SlotMachineIcon as unknown as string,
		useCase: 'End User Experience Innovation',
		filters: [GAME_TOPIC_FILTERS.CUSTOMER_EXPERIENCE],
		description:
			'Given a customer persona, what innovative solutions can you create?',
		gameLink: SIMON_SAYS,
		image: SimonSaysBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(5, 0, 230, 0.225) 0%, rgba(116, 35, 249, 0.125) 100%);			',
		useCaseBackgroundColor: '#A875FF',
		subtitle: 'Let’s look at our business from new customer perspectives. ',
		subtitle2:
			'Choose an innovation topic and solve for a variety of generated customer problems, even if their name isn’t Simon.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
		shortDesc: 'for broadening perspectives',
	},
	BATTLE_SHIPS: {
		value: 3,
		label: 'Battle Ships',
		icon: BattleShipsIcon as unknown as string,
		useCase: 'Disaster Planning',
		filters: [
			GAME_TOPIC_FILTERS.SALES,
			GAME_TOPIC_FILTERS.CUSTOMER_EXPERIENCE,
			GAME_TOPIC_FILTERS.OPERATIONS,
		],
		description:
			'Plan for potential company disruptions and competitor reactions to them.',
		gameLink: BATTLE_SHIPS,
		image: BattleShipsBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(230, 92, 0, 0.3) 0%, rgba(249, 212, 35, 0.3) 100%)',
		useCaseBackgroundColor: '#FFAA7E',
		shortDesc: 'for proactively competing',
		subtitle:
			'Transforming your business through some friendly competition.',
		subtitle2:
			'Plan for how both you and your competitors might strategically respond to unexpected disruptions in your company.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
	},
	LEAP_FROG: {
		value: 5,
		label: 'Leap Frog',
		icon: LeapFrogIcon as unknown as string,
		useCase: 'Random Element Inspiration',
		filters: [
			GAME_TOPIC_FILTERS.MARKETING,
			GAME_TOPIC_FILTERS.SALES,
			GAME_TOPIC_FILTERS.CUSTOMER_EXPERIENCE,
		],
		description:
			'Explore how random elements can prompt lateral creative solutions.',
		gameLink: LEAP_FROG,
		image: LeapFrogBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(62, 157, 18, 0.3) 0%, rgba(209, 242, 167, 0.3) 100%);',
		useCaseBackgroundColor: '#488F4E',
		shortDesc: 'for problem statement responses',
		subtitle: 'Let’s use random elements to jump to new conclusions.',
		subtitle2:
			'Dive into a world of creativity as you leap from idea to idea, drawing inspiration from four random elements to fuel brainstorming for your innovation topic.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
	},
	TEETER_TOTTER: {
		value: 7,
		label: 'Teeter Totter',
		icon: '',
		useCase: 'Extreme Scenario Inspiration',
		filters: [
			GAME_TOPIC_FILTERS.MARKETING,
			GAME_TOPIC_FILTERS.SALES,
			GAME_TOPIC_FILTERS.CUSTOMER_EXPERIENCE,
			GAME_TOPIC_FILTERS.OPERATIONS,
		],
		description:
			'Think from opposite ends of various spectrums to uncover new solutions.',

		gameLink: TEETER_TOTTER,
		image: TeeterTotterBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(255, 0, 31, 0.25) 0%, rgba(255, 161, 189, 0.25) 100%);',
		useCaseBackgroundColor: '#FF437B',
		subtitle: '',
		subtitle2:
			"Innovation begins at the edge of what's familiar. Looking at both extremes of a given prompt can unlock new perspectives toward a problem and its potential solutions. ",
		modalText: {
			chipsQuestion: 'What business topic do we want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
		shortDesc: '',
	},
	MUSICAL_CHAIRS: {
		value: 2,
		label: 'Musical Chairs',
		icon: MusicalChairsIcon as unknown as string,
		useCase: 'Process Innovation',
		filters: [GAME_TOPIC_FILTERS.OPERATIONS],
		description:
			'Challenge process flows by removing and re-arranging process steps.',
		gameLink: MUSICAL_CHAIRS,
		image: MusicalChairsBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(14, 148, 166, 0.25) 0%, rgba(144, 218, 227, 0.25) 100%);',
		useCaseBackgroundColor: '#2CAABB',
		subtitle:
			'Sometimes the way things have always been can inhibit the way things could be.',
		subtitle2:
			'Similar to how removing chairs when the music stops forces you to get creative in finding a seat, removing and shifting process steps can spark creative paths to the same outcomes.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
	},
	ROUND_ROBIN: {
		value: 6,
		label: 'Round Robin',
		icon: RoundRobinIcon as unknown as string,
		useCase: 'Existing Idea Refinement',
		filters: [
			GAME_TOPIC_FILTERS.MARKETING,
			GAME_TOPIC_FILTERS.SALES,
			GAME_TOPIC_FILTERS.CUSTOMER_EXPERIENCE,
			GAME_TOPIC_FILTERS.OPERATIONS,
		],
		description:
			'Consider the context around launching and scaling your favorite ideas.',
		gameLink: ROUND_ROBIN,
		image: RoundRobinBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(236, 0, 140, 0.25) 0%, rgba(252, 103, 103, 0.25) 100%)',
		useCaseBackgroundColor: '#BB2C9C',
		shortDesc: 'for challenging assumptions',
		subtitle: 'Refine your idea by looking at it from different angles.',
		subtitle2:
			'Explore the industry context, identify potential challenges, plan practical tests, and consider avenues to scale in order to prepare your initial idea for implementation.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder: 'Summarize within 5-10 words',
		},
	},
	OPPOSITE_DAY: {
		value: 8,
		label: 'Opposite Day',
		icon: '' as unknown as string,
		useCase: '',
		filters: [],
		description: '',
		gameLink: '',
		image: OppositeDayBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(194, 224, 248, 255) 0%, rgba(238, 248, 255, 255) 100%);',
		useCaseBackgroundColor: '#DCEFFE',
		shortDesc: '',
		subtitle:
			'Coming up with the next great idea can feel like a lot of pressure.',
		subtitle2:
			'But considering the worst possible ideas is more fun and less pressure as a starting point, and thinking about the opposite of - or perhaps an iteration of - bad concepts can lead to novelty.',
		modalText: {
			chipsQuestion: 'What business topic do you want to focus on?',
			inputQuestion:
				'Summarize the business problem you want to ideate on (in 5-10 words).',
			inputPlaceholder:
				'Enter business problem here. Summarize within 5-10 words.',
		},
	},
	BUILDING_BLOCKS: {
		value: 9,
		label: 'Building Blocks',
		icon: '' as unknown as string,
		useCase: '',
		filters: [],
		description: '',
		gameLink: '',
		image: BuildingBlocksBannerImage as unknown as string,
		backgroundGradient:
			'linear-gradient(90deg, rgba(2239, 198, 207, 255) 0%, rgba(255, 235, 238, 255) 100%);',
		useCaseBackgroundColor: '#FEF1F3',
		shortDesc: '',
		subtitle:
			"React and build off of GenAI's initial reaction to your business problem.",
		subtitle2:
			"Curating GenAI's results won't lead to novelty, but it is helpful in reaching parity and can serve as the foundation for further ideation.",
		modalText: {
			chipsQuestion: 'What business function do you want to focus on?',
			inputQuestion: 'What company are you brainstorming about today?',
			inputPlaceholder:
				'Do you want to focus on a specific part of that business function (within 5-10 words)?',
		},
	},
	SLOT_MACHINE: {
		value: 0,
		label: 'Slot Machine',
		icon: SlotMachineIcon as unknown as string,
		gameTitle: '',
		useCase: '',
		filters: [],
		description: '',
		gameLink: '',
		image: '',
		backgroundGradient: '',
		useCaseBackgroundColor: '',
		shortDesc: '',
		subtitle: '',
		subtitle2: '',
		modalText: {
			chipsQuestion: '',
			inputQuestion: '',
			inputPlaceholder: '',
		},
	},
	CONNECT_FOUR: {
		value: 0,
		label: 'Connect Four',
		icon: LeapFrogIcon as unknown as string,
		useCase: '',
		filters: [],
		description: '',
		gameLink: '',
		image: '',
		backgroundGradient: '',
		useCaseBackgroundColor: '',
		shortDesc: '',
		subtitle: '',
		subtitle2: '',
		modalText: {
			chipsQuestion: '',
			inputQuestion: '',
			inputPlaceholder: '',
		},
	},
}
