import { useState } from 'react'

import logger from 'utils/logger'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { MusicalChairsPromptResponse } from './ideaModels'

export type Conversation = {
	content: string
	role: string
}

export function MusicalChairsModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [businessComparisons, setBusinessComparisons] = useState<string[]>([
		'',
		'',
		'',
		'',
	])
	const [businessComparisonsHasChanged, setBusinessComparisonsHasChanged] =
		useState<boolean[]>([false, false, false, false])
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [currentResponses, setCurrentResponses] = useState<string[]>([
		'',
		'',
		'',
		'',
	])
	const [gameId, setGameId] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')

	const [promptConversation, setPromptConversation] = useState<
		Conversation[]
	>([])
	const [responseConversation, setResponseConversation] = useState<
		Conversation[][]
	>([[], [], [], []])
	const [termsAgreed, setTermsAgreed] = useState(false)

	const gameTypeId = GAME_TYPE.MUSICAL_CHAIRS.value

	const [musicalChairsPrompt, setMusicalChairsPrompt] = useState<string[][]>([
		['', ''],
		['', ''],
		['', ''],
		['', ''],
	])
	const setPromptResponse = (response: MusicalChairsPromptResponse) => {
		try {
			let promptData: string[][] = []

			if (response.data && response.data.prompt) {
				promptData = response.data.prompt
			}

			// Ensure we have 4 entries for consistency
			while (promptData.length < 4) {
				const nextIdx = promptData.length + 1
				promptData.push([nextIdx.toString(), `Step ${nextIdx}`, ''])
			}

			setMusicalChairsPrompt(promptData)
			setBusinessComparisons(['', '', '', ''])
		} catch (error) {
			logger.error('Error in setPromptResponse:', error)
		}
	}

	const generateScenarioDisabled = () => {
		return (
			!innovationTopic.length ||
			!innovationCompany.length ||
			!companyProblem.length
		)
	}

	const generateNewScenarioDisabled = () => {
		return generateScenarioDisabled() || !musicalChairsPrompt
	}

	const submitIdeasDisabled = () => {
		if (
			generateScenarioDisabled() ||
			!authorIdeation ||
			(gameId && !termsAgreed)
		)
			return true
		return false
	}

	const promptToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
			conversation: promptConversation,
		}
	}

	const responseToJSON = (i: number) => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
			prompt: businessComparisons[i],
			conversation: responseConversation[i],
		}
	}

	const resetSoft = () => {
		setMusicalChairsPrompt([
			['', ''],
			['', ''],
			['', ''],
			['', ''],
		])
		setResponseConversation([[], [], [], []])
		setAuthorIdeation('')
		setCurrentResponses(['', '', '', ''])
		setBusinessComparisons(['', '', '', ''])
		setBusinessComparisonsHasChanged([false, false, false, false])
	}

	const reset = () => {
		setPromptConversation([])
		resetSoft()
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}

	return {
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		promptConversation,
		setPromptConversation,
		innovationTopic,
		setInnovationTopic,
		setPromptResponse,
		businessComparisons,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptToJSON,
		responseToJSON,
		resetSoft,
		submitIdeasDisabled,
		gameToJSON,
		reset,
		gameId,
		setGameId,
		businessComparisonsHasChanged,
		setBusinessComparisonsHasChanged,
		termsAgreed,
		setTermsAgreed,
		musicalChairsPrompt,
	}
}
